import styles from "./salaryPaymentListInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import { useEffect } from "react";

import LoadedComponent from "widgets/LoadedComponent";
import SearchInput from "shared/ui/Inputs/SearchInput";
import { Formik } from "formik";
import Select from "shared/ui/Inputs/Select";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";

const SalaryPaymentlistOneFilters = () => {
  const { id } = useParams();
  const { salaryPaymentlistOneStore } = useStores();

  useEffect(() => {
    if (!salaryPaymentlistOneStore.itrFilter[id]?.length) {
      salaryPaymentlistOneStore.setItrFilter("all", id);
    }
  }, [id]);

  return (
    <LoadedComponent isLoading={salaryPaymentlistOneStore.isLoading}>
      {Object.values(salaryPaymentlistOneStore.selectedOnePayment).length &&
      Object.values(salaryPaymentlistOneStore.paymentCols).length &&
      Object.values(salaryPaymentlistOneStore.paymentParams).length &&
      Object.values(salaryPaymentlistOneStore.paymentStatuses).length ? (
        <div className={styles.filtersContainer}>
          <div className={styles.itrFilters}>
            <div className={styles.itrFilters__label}>Сотрудники</div>
            <ButtonsTabs
              tabs={[
                {
                  id: "all",
                  title: "Все"
                },
                {
                  id: "itr",
                  title: "ИТР"
                },
                {
                  id: "not_itr",
                  title: "не ИТР"
                }
              ]}
              selectedTab={salaryPaymentlistOneStore.itrFilter[id]}
              changeActiveTab={(name: string) =>
                salaryPaymentlistOneStore.setItrFilter(name, id)
              }
            />
          </div>
          <div className={styles.searchInput}>
            <div className={styles.searchInput__label}>ФИО</div>
            <SearchInput
              placeholder="Введите ФИО"
              onChange={(e) => {
                salaryPaymentlistOneStore.setSearchInput(
                  e.target.value.toLowerCase(),
                  id
                );
              }}
              value={salaryPaymentlistOneStore.searchInput[id] || ""}
              clearSearch={() => {
                salaryPaymentlistOneStore.setSearchInput("", id);
              }}
            />
          </div>
          <div className={styles.positionFilter}>
            <div className={styles.positionFilter__label}>Должность</div>
            <Formik
              initialValues={{ position: "" }}
              onSubmit={(value) =>
                salaryPaymentlistOneStore.setPositionFilter(value.position, id)
              }
            >
              {() => {
                return (
                  <Select
                    name="position"
                    options={
                      salaryPaymentlistOneStore.positionsList as {
                        [key: string]: { title: string; id: string };
                      }
                    }
                    valueName="id"
                    onChange={(value) => {
                      salaryPaymentlistOneStore.setPositionFilter(value, id);
                    }}
                    label={
                      salaryPaymentlistOneStore.positionFilter[id]?.length
                        ? salaryPaymentlistOneStore.positionsList[
                            salaryPaymentlistOneStore.positionFilter[id]
                          ]?.title
                        : ""
                    }
                  />
                );
              }}
            </Formik>
          </div>
        </div>
      ) : null}
    </LoadedComponent>
  );
};

export default observer(SalaryPaymentlistOneFilters);
