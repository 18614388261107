import styles from "./aregisterAllReportNewReportTable.module.scss";
import { observer } from "mobx-react-lite";

import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Formik } from "formik";

import { useStores } from "stores/index";
import { NewReportStaffValues } from "stores/AregisterModule/types/NewReportStaffValues";
import { classNames } from "shared/utils/helpers/classNames";

import iconAddLine from "shared/assets/images/mainIcons/iconAddLine/iconAddLine.svg";
import arrowGroup from "shared/assets/images/mainIcons/arrowsGroup.svg";
import LoadedComponent from "widgets/LoadedComponent";

import SelectMulti from "shared/ui/Inputs/SelectMulti";
import Switch from "shared/ui/Inputs/Switch";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import Modal from "shared/ui/Modal";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import Tooltip from "shared/ui/Tooltip";
import { Input } from "shared/ui/Inputs/Input";
import StatusIcon from "shared/ui/StatusIcon";

import AregisterAllReportNewReportTableCell from "./AregisterAllReportNewReportTableCell";
import AregisterAllReportNewReportTableRow from "./AregisterAllReportNewReportTableRow";
import AregisterAllReportNewReportTableHeader from "./AregisterAllReportNewReportTableHeader";
import { Button, ButtonTheme } from "shared/ui/Button";

type AregisterAllReportNewReportTableProps = {
  date_start: string;
  date_end: string;
  project_id: string;
};

const AregisterAllReportNewReportTable = ({
  date_start,
  date_end,
  project_id
}: AregisterAllReportNewReportTableProps) => {
  const initialValues: NewReportStaffValues = {
    staffListForAdding: [],
    filledHoursStaffIds: [],
    selectAll: 0,
    massAddHoursInputValue: null,
    columnsAmount: 1
  };
  const { aregisterReportStore, menuStore } = useStores();
  const [isOpenStaffList, setIsOpenStaffList] = useState(false);
  const [editMassHoursPresaveId, setEditMassHoursPresaveId] = useState("");
  const [columnNumber, setColumnNumber] = useState<number>(null);
  const [highlightedColumn, setHighlightedColumn] = useState(-1);
  const [isOpenedMastersList, setIsOpenedMastersList] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [focusedRowCell, setFocusedRowCell] = useState(0);
  const [focusedColCell, setFocusedColCell] = useState(0);
  const [isEditingCell, setIsEditingCell] = useState(false);
  const [isHoveredCell, setIsHoveredCell] = useState(true);
  const [deletingPresaveModal, setDeletingPresaveModal] = useState("");

  let rowNumber = 0;
  const maxCol = aregisterReportStore.filteredPresaveList.length - 1;

  useEffect(() => {
    document.addEventListener("keydown", handleArrowKeys);
    return () => document.removeEventListener("keydown", handleArrowKeys);
  });

  const handleArrowKeys = (e: { keyCode: number }) => {
    setIsHoveredCell(false);
    setIsEditingCell(true);
    if (e.keyCode === 37 && focusedColCell > 0) {
      setFocusedColCell(focusedColCell - 1);
    }
    if (e.keyCode === 38 && focusedRowCell > 1) {
      setFocusedRowCell(focusedRowCell - 1);
    }
    if (e.keyCode === 39 && focusedColCell < maxCol) {
      setFocusedColCell(focusedColCell + 1);
    }
    if ((e.keyCode === 40 && focusedRowCell < rowNumber) || e.keyCode === 13) {
      setFocusedRowCell(focusedRowCell + 1);
    }
  };

  const onKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === 27) {
      isOpenStaffList ? setIsOpenStaffList(false) : "";
    }
  };

  return aregisterReportStore.filteredPresaveList.length ? (
    <div className={styles.loading}>
      <LoadedComponent isLoading={aregisterReportStore.isLoadingForTable}>
        <Formik
          initialValues={{
            ...initialValues,
            ...aregisterReportStore.presaveTempNums
          }}
          onSubmit={() => {
            return;
          }}
        >
          {({
            values,
            setFieldTouched,
            setFieldValue,
            isValid,
            dirty,
            handleReset
          }) => {
            const btns: {
              action: string;
              type: "button" | "submit" | "reset";
              icon: string;
              color: string;
              disabled?: boolean;
            }[] = [
              {
                action: "cancel",
                type: "button",
                icon: "iconclose",
                color: !values.staffListForAdding.length
                  ? "bw-gray3"
                  : "blue-lazure",
                disabled: !values.staffListForAdding.length
              },
              {
                action: "submit",
                type: "button",
                icon: "iconcheck",
                color: !values.staffListForAdding.length
                  ? "bw-gray3"
                  : "blue-lazure",
                disabled: !values.staffListForAdding.length
              }
            ];
            const addStaff = (action: string) => {
              if (action === "submit") {
                aregisterReportStore.addStaffRow(values.staffListForAdding);
              }
              if (action !== "submit" && action !== "cancel") {
                return;
              }
              Object.keys(values.staffListForAdding).forEach(
                (key) => delete values.staffListForAdding[key]
              );
              setIsOpenStaffList(false);
            };

            rowNumber = 0;

            const getListStyle = (isDraggingOver: boolean) => ({
              background: isDraggingOver ? "#F2F9FF" : ""
            });

            const selectAllStaff = () => {
              if (!values.selectAll) {
                const updatedValue: string[] = [];
                aregisterReportStore.presaveStaffList.forEach((item) => {
                  for (const company of Object.keys(item.company_ws)) {
                    updatedValue.push(`${item.uid}_${company}`);
                  }
                });
                setFieldValue("filledHoursStaffIds", updatedValue);
                setFieldTouched("filledHoursStaffIds");
              } else {
                handleReset();
              }
            };

            return (
              <div className={styles.tableContainer}>
                <div className="d-flex flex-column">
                  <div className={styles.notification}>
                    Доступно перемещение по ячейкам при помощи клавиатуры{" "}
                    <img src={arrowGroup} alt="arrow_icon" />
                  </div>
                  <DragDropContext
                    onDragEnd={(result) => {
                      aregisterReportStore.handleDragEnd(result);
                    }}
                  >
                    <Droppable droppableId="droppable" type="group">
                      {(provided, snapshot) => (
                        <table
                          className={styles.table}
                          id="table"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          <AregisterAllReportNewReportTableHeader
                            setIsOpenStaffList={setIsOpenStaffList}
                            highlightedColumn={highlightedColumn}
                            setHighlightedColumn={setHighlightedColumn}
                            columnNumber={columnNumber}
                            setColumnNumber={setColumnNumber}
                            setEditMassHoursPresaveId={
                              setEditMassHoursPresaveId
                            }
                            setDeletingPresaveModal={setDeletingPresaveModal}
                          />
                          {Object.keys(aregisterReportStore.presaveStaffList)
                            .length
                            ? Object.values(
                                aregisterReportStore.presaveStaffList
                              ).map((staff, staffIdx) => {
                                let rowSelected = false;
                                for (const company of Object.keys(
                                  staff.company_ws
                                )) {
                                  if (
                                    values.filledHoursStaffIds?.includes(
                                      `${staff.uid}_${company}`
                                    )
                                  ) {
                                    rowSelected = true;
                                    break;
                                  }
                                }
                                return (
                                  <Draggable
                                    key={`${staff.uid}_${staffIdx}`}
                                    draggableId={staff.uid}
                                    index={staffIdx}
                                  >
                                    {(provided, snapshot) => {
                                      const style = {
                                        ...provided.draggableProps.style,
                                        borderColor:
                                          snapshot.isDragging && "#008CFF",
                                        borderWidth:
                                          snapshot.isDragging && "1px"
                                      };
                                      return (
                                        <tbody
                                          className={classNames("", {
                                            [styles.rowSelected]: rowSelected
                                          })}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={style}
                                        >
                                          {Object.keys(staff.company_ws).map(
                                            (company, companyIdx) => {
                                              const localRowNumber =
                                                (rowNumber += 1);
                                              return (
                                                <AregisterAllReportNewReportTableRow
                                                  className={classNames("", {
                                                    [styles.draggingRow]:
                                                      snapshot.isDragging
                                                  })}
                                                  key={`${staff.uid}_${companyIdx}`}
                                                  company={company}
                                                  companyIdx={companyIdx}
                                                  staff={staff}
                                                  staffIdx={staffIdx}
                                                  columnNumber={columnNumber}
                                                  isEditingCell={isEditingCell}
                                                  setIsEditingCell={
                                                    setIsEditingCell
                                                  }
                                                  setIsOpenStaffList={
                                                    setIsOpenStaffList
                                                  }
                                                  editMassHoursPresaveId={
                                                    editMassHoursPresaveId
                                                  }
                                                  highlightedColumn={
                                                    highlightedColumn
                                                  }
                                                  setHighlightedColumn={
                                                    setHighlightedColumn
                                                  }
                                                  localRowNumber={
                                                    localRowNumber
                                                  }
                                                  focusedRowCell={
                                                    focusedRowCell
                                                  }
                                                  setFocusedRowCell={
                                                    setFocusedRowCell
                                                  }
                                                  focusedColCell={
                                                    focusedColCell
                                                  }
                                                  setFocusedColCell={
                                                    setFocusedColCell
                                                  }
                                                  handleArrowKeys={
                                                    handleArrowKeys
                                                  }
                                                  isHoveredCell={isHoveredCell}
                                                  searchInputValue={
                                                    aregisterReportStore.searchValue
                                                  }
                                                  rowSelected={rowSelected}
                                                />
                                              );
                                            }
                                          )}
                                        </tbody>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })
                            : null}
                          {provided.placeholder}
                          <tfoot
                            className={classNames("", {
                              [styles.footerShadow]: menuStore.isScroll
                            })}
                            id="foot"
                          >
                            {isOpenStaffList && (
                              <tr
                                className={styles.openedStaffList}
                                onKeyDown={onKeyDown}
                                tabIndex={-1}
                              >
                                <td className={styles.row}>
                                  <span className={styles.addRowBtns}>
                                    <ButtonsGroupForEdit
                                      btns={btns}
                                      onClick={addStaff}
                                    />
                                  </span>
                                  <div className={styles.selectMulti}>
                                    <SelectMulti
                                      name="staffListForAdding"
                                      options={
                                        aregisterReportStore.staffForAdding as {
                                          [key: string]: {
                                            title: string;
                                            id: string;
                                          };
                                        }
                                      }
                                      isMenuOpened={isOpenedMastersList}
                                      setIsMenuOpened={() => {
                                        setIsOpenedMastersList(
                                          !isOpenedMastersList
                                        );
                                      }}
                                      valueName="id"
                                      notSearchable
                                      aboveContainer
                                      withoutButtons
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                            <tr>
                              <td
                                className={classNames(styles.number, {}, [
                                  styles.number__empty
                                ])}
                              >
                                {aregisterReportStore.presaveStaffList.length}
                              </td>
                              <td
                                className={classNames(styles.addLineBtnCell, {
                                  [styles.addLineBtnCell__shadow]:
                                    menuStore.scroll["scrollLeft"]
                                })}
                                onClick={() => {
                                  aregisterReportStore.getStaffForPresave(
                                    project_id,
                                    date_start,
                                    date_end
                                  );
                                  setIsOpenStaffList(!isOpenStaffList);
                                  setIsEditingCell(false);
                                  handleReset();
                                }}
                                id="AregisterAllReportNewReportTable_openStaffList"
                              >
                                <Tooltip
                                  text="Добавить сотрудников"
                                  placement="right"
                                >
                                  <button
                                    type="button"
                                    className={styles.addIcon}
                                    onKeyDown={onKeyDown}
                                  >
                                    <img src={iconAddLine} alt="add_staff" />
                                  </button>
                                </Tooltip>
                              </td>
                              {aregisterReportStore.filteredPresaveList.map(
                                (presave, presaveIdx) => {
                                  return (
                                    <AregisterAllReportNewReportTableCell
                                      key={`workHoursTotal_${presave.id}`}
                                      column={presaveIdx}
                                      highlightedColumn={highlightedColumn}
                                      setHighlightedColumn={
                                        setHighlightedColumn
                                      }
                                      className={classNames("", {
                                        [styles.hiddenColumn]:
                                          columnNumber !== null &&
                                          columnNumber !== presaveIdx
                                      })}
                                    >
                                      <span>{presave.presave_ws}</span>
                                    </AregisterAllReportNewReportTableCell>
                                  );
                                }
                              )}
                              <td
                                className={classNames(
                                  styles.total,
                                  {
                                    [styles.total__shadow]:
                                      menuStore.scroll["scrollLeft"]
                                  },
                                  [styles.total__white]
                                )}
                              >
                                {aregisterReportStore.totalSum}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                {editMassHoursPresaveId && (
                  <div className={styles.massHours}>
                    <div className={styles.massHours__header}>
                      Массовое заполнение часов
                    </div>
                    <div className={styles.massHours__yellowBackground}>
                      Отмечено строк:{" "}
                      {`${values.filledHoursStaffIds?.length} из ${aregisterReportStore.companiesQuantity}`}
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className={styles.switch}>
                        <Switch name="selectAll" onChange={selectAllStaff} />
                        <span className={styles.massHours__switchTitle}>
                          Отметить всех
                        </span>
                      </label>
                      <Button
                        type="button"
                        theme={ButtonTheme.CLEAR}
                        disabled={Boolean(values.filledHoursStaffIds.length)}
                        onClick={handleReset}
                        id="AregisterAllReportNewReportTable_cancelAddMassHours"
                      >
                        <StatusIcon
                          icon="iconclose"
                          color={
                            values.filledHoursStaffIds.length
                              ? "blue-lazure"
                              : "bw-gray5"
                          }
                        />
                        Сбросить отмеченные
                      </Button>
                    </div>
                    <NumberFormat
                      name="massAddHoursInputValue"
                      label="Часы"
                      allowNegative={false}
                      isAllowed={({ formattedValue, floatValue }) =>
                        formattedValue === "" || floatValue <= 24
                      }
                      customInput={Input}
                    />
                    <ButtonsGroupForFormEdit
                      grayBtnTitle="Отмена"
                      blueBtnTitle="Заполнить"
                      blueBtnOnClick={() => {
                        aregisterReportStore.changePresaveHoursMass(
                          editMassHoursPresaveId,
                          values.massAddHoursInputValue,
                          values.filledHoursStaffIds
                        );
                        setTimeout(() => {
                          setEditMassHoursPresaveId("");
                          setIsOpenSuccessModal(true);
                        }, 500);
                      }}
                      blueBtnDisabled={!dirty || !isValid}
                      grayBtnOnClick={() => {
                        handleReset();
                        setEditMassHoursPresaveId("");
                        setColumnNumber(null);
                      }}
                    />
                  </div>
                )}
                {isOpenSuccessModal ? (
                  <Modal
                    type="success"
                    successIcon
                    show={isOpenSuccessModal}
                    onHide={() => {
                      handleReset();
                      setIsOpenSuccessModal(false);
                      setColumnNumber(null);
                      setEditMassHoursPresaveId("");
                    }}
                    title="Часы заполнены"
                    direction="row"
                  />
                ) : (
                  ""
                )}
                {deletingPresaveModal ? (
                  <Modal
                    type="clarification"
                    show={Boolean(deletingPresaveModal)}
                    onHide={() => {
                      setDeletingPresaveModal("");
                    }}
                    title="Вы уверены, что хотите удалить почасовку?"
                    btnWithCrossTitle="Удалить"
                    btnWithCrossOnClick={() => {
                      aregisterReportStore.deletePresave(deletingPresaveModal);
                      setDeletingPresaveModal("");
                    }}
                    blueBtnOnClick={() => {
                      setDeletingPresaveModal("");
                    }}
                    blueBtnTitle="Отмена"
                    btnWithCrossImg
                  />
                ) : (
                  ""
                )}
              </div>
            );
          }}
        </Formik>
      </LoadedComponent>
    </div>
  ) : null;
};

export default observer(AregisterAllReportNewReportTable);
