import styles from "./aregisterAllReportNewReportTableHeader.module.scss";

import { observer } from "mobx-react-lite";
import { useState, useRef, Fragment } from "react";
import { useNavigate } from "react-router";
import { useFormikContext } from "formik";

import { useStores } from "stores/index";
import { NewReportStaffValues } from "stores/AregisterModule/types/NewReportStaffValues";
import { classNames } from "shared/utils/helpers/classNames";
import { format, parse } from "date-fns";

import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import iconLockOpen from "shared/assets/images/mainIcons/iconLockOpen.svg";
import iconLock from "shared/assets/images/mainIcons/iconLock.svg";
import iconCancel from "shared/assets/images/mainIcons/iconCancel.svg";
import iconCopy from "shared/assets/images/mainIcons/iconCopyActive.svg";
import iconMultiEdit from "shared/assets/images/mainIcons/iconMultiEditActive.svg";
import { ReactComponent as IconAddColumn } from "shared/assets/images/mainIcons/iconAddColumn/iconAddColumn.svg";
import { ReactComponent as IconQuestionSmall } from "shared/assets/images/mainIcons/iconQuestionSmall/iconQuestionSmallWithoutStroke.svg";

import Select from "shared/ui/Inputs/Select";
import SearchInput from "shared/ui/Inputs/SearchInput";
import { InputNumber } from "shared/ui/Inputs/InputNumber";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";

import AregisterAllReportNewReportTableHeaderCell from "../AregisterAllReportNewReportTableHeaderCell";
import AregisterAllReportNewReportTableCell from "../AregisterAllReportNewReportTableCell";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

//хардкод
const headerTitles = [
  "date_active",
  "date_start",
  "title",
  "master_fio",
  "brigadier_fio",
  "temporary_num"
];

const tableCols = {
  date_active: {
    title: ""
  },
  date_start: {
    title: "Дата"
  },
  title: {
    title: "Номер заявки"
  },
  master_fio: {
    title: "Мастер заявки"
  },
  brigadier_fio: {
    title: "Бригадир"
  },
  temporary_num: {
    title: "Временный номер акта"
  }
};

type AregisterAllReportNewReportTableHeaderProps = {
  setIsOpenStaffList: (arg: boolean) => void;
  highlightedColumn: number;
  setHighlightedColumn: (arg: number) => void;
  columnNumber: number;
  setColumnNumber: (arg: number) => void;
  setEditMassHoursPresaveId: (arg: string) => void;
  setDeletingPresaveModal: (arg: string) => void;
};

const AregisterAllReportNewReportTableHeader = ({
  setIsOpenStaffList,
  highlightedColumn,
  setHighlightedColumn,
  columnNumber,
  setColumnNumber,
  setEditMassHoursPresaveId,
  setDeletingPresaveModal
}: AregisterAllReportNewReportTableHeaderProps) => {
  const { aregisterReportStore, modalWindowsStore, menuStore } = useStores();
  const [searchInputValue, setSearchInputValue] = useState(
    aregisterReportStore.searchValue
  );
  const [openedWindow, setOpenedWindow] = useState("");
  const { setFieldValue, setFieldTouched, values } =
    useFormikContext<NewReportStaffValues>();
  const navigate = useNavigate();

  const navigateToApplication = (id: string) => {
    navigate(`../aregister/id=${id}`);
    menuStore.setScrollPosition(menuStore.scroll);
  };

  const inputRef = useRef<HTMLInputElement>();

  const changeOpenedWindow = (name: string) => {
    openedWindow === name ? setOpenedWindow(null) : setOpenedWindow(name);
  };

  const handleFindData = () => {
    aregisterReportStore.setSearchValue(searchInputValue);
    inputRef.current.blur();
  };

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    //регулярное выражение "только цифры"
    const value = e.target.value.toLowerCase().match(/^\d+$/);
    setSearchInputValue(value ? value[0] : "");
  };

  const clearSearch = () => {
    setSearchInputValue("");
    if (aregisterReportStore.searchValue) {
      aregisterReportStore.setSearchValue("");
    }
  };

  return (
    <thead className={menuStore.isScroll ? styles.headerShadow : ""}>
      {headerTitles.map((title) => {
        return (
          <tr key={`thead_${title}`}>
            {aregisterReportStore.filteredPresaveList.map(
              (presave, presaveIndex) => {
                const aregister =
                  aregisterReportStore.filteredAregisterList[
                    presave["aregister"]
                  ] !== undefined &&
                  aregisterReportStore.filteredAregisterList?.[
                    presave["aregister"]
                  ];

                const closed =
                  aregisterReportStore.aregisterStatus[aregister.status]
                    ?.title === "Закрыта";
                const canceled =
                  aregisterReportStore.aregisterStatus[aregister.status]
                    ?.title === "Аннулирована";

                const handlePresaveChange = (action: string) => () => {
                  switch (action) {
                    case "delete":
                      setDeletingPresaveModal(presave.id);
                      break;
                    case "unpin":
                      aregisterReportStore.unpinPresave(presave.id);
                      break;
                    case "copy":
                      aregisterReportStore.duplicatePresave(presave.id);
                      break;
                    case "nav":
                      navigateToApplication(presave.aregister);
                      break;
                    case "create":
                      setIsOpenStaffList(false);
                      aregisterReportStore.createPresave(
                        aregisterReportStore.selectedOptions.project_id,
                        aregisterReportStore.selectedOptions.date_start,
                        aregisterReportStore.selectedOptions.date_end,
                        aregisterReportStore.selectedOptions.masterId,
                        values.columnsAmount
                      );
                      break;
                    case "update":
                      if (values[`temporary_num_${presave.id}`] !== undefined)
                        aregisterReportStore.updatePresave(
                          presave.id,
                          format(
                            parse(
                              presave.date_active,
                              "dd.MM.yyyy",
                              new Date()
                            ),
                            "yyyy-MM-dd"
                          ),
                          values[`temporary_num_${presave.id}`]
                        );
                      break;

                    case "openAddMassHoursMode":
                      setColumnNumber(presaveIndex);
                      setEditMassHoursPresaveId(presave.id);
                      setIsOpenStaffList(false);
                      break;
                  }
                };

                const hadleChangePresavePin = (value: string) => {
                  if (value) {
                    aregisterReportStore.pinPresave(presave.id, value);
                  } else return;
                };

                const handleChangeTempNum = (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setFieldValue(
                    `temporary_num_${presave.id}`,
                    event.target.value
                  );
                  setFieldTouched(event.target.value);
                };

                switch (title) {
                  case "date_active":
                    return (
                      <Fragment key={`tn_${presave.id}`}>
                        {!presaveIndex && (
                          <>
                            <th
                              className={classNames(
                                styles.addPresavesIconCell,
                                {
                                  [styles.addPresavesIconCell__disabled]:
                                    values.columnsAmount >= 25
                                }
                              )}
                              onClick={handlePresaveChange("create")}
                              id="AregisterAllReportNewReportTableHeader_createPresave"
                            >
                              <Tooltip
                                text={`${
                                  values.columnsAmount > 1
                                    ? "Добавить ещё"
                                    : "Добавить"
                                } почасовки: ${values.columnsAmount}`}
                              >
                                <div
                                  className={classNames(
                                    styles.addPresavesIcon,
                                    {
                                      [styles.addPresavesIcon__disabled]:
                                        values.columnsAmount >= 25
                                    }
                                  )}
                                >
                                  <IconAddColumn />
                                </div>
                              </Tooltip>
                            </th>
                            <th
                              className={classNames(styles.tN, {
                                [styles.tN__shadow]:
                                  menuStore.scroll["scrollLeft"]
                              })}
                            >
                              <div className="d-flex align-items-center">
                                <div className={styles.inputNumber}>
                                  <InputNumber
                                    name="columnsAmount"
                                    value={values.columnsAmount}
                                    minValue={1}
                                    maxValue={25}
                                    smallInputWithoutBorder
                                  />
                                </div>
                                <SearchInput
                                  inputRef={inputRef}
                                  onChange={onChangeSearchInput}
                                  placeholder="ТН"
                                  value={searchInputValue}
                                  handleFindData={handleFindData}
                                  blurCondition={
                                    aregisterReportStore.isLoading ||
                                    modalWindowsStore.isErrorWindow
                                  }
                                  clearSearch={clearSearch}
                                  withoutSearchButton
                                />
                              </div>
                            </th>
                          </>
                        )}
                        <AregisterAllReportNewReportTableCell
                          className={classNames(
                            styles.presaveCell,
                            {
                              [styles.hiddenColumn]:
                                columnNumber !== null &&
                                columnNumber !== presaveIndex
                            },
                            [styles.presaveCell__white]
                          )}
                          column={presaveIndex}
                          highlightedColumn={highlightedColumn}
                          setHighlightedColumn={setHighlightedColumn}
                        >
                          {highlightedColumn === presaveIndex ? (
                            <div className={styles.manageBtns}>
                              <Tooltip text="Копировать почасовку">
                                <button
                                  type="button"
                                  className={styles.manageBtn}
                                  onClick={handlePresaveChange("copy")}
                                  id="AregisterAllReportNewReportTableHeader_copyPresave"
                                >
                                  <img src={iconCopy} alt="copy_presave" />
                                </button>
                              </Tooltip>
                              {!canceled && !closed && (
                                <>
                                  <Tooltip text="Массовое заполнение">
                                    <button
                                      type="button"
                                      className={styles.manageBtn}
                                      onClick={handlePresaveChange(
                                        "openAddMassHoursMode"
                                      )}
                                      id="AregisterAllReportNewReportTableHeader_openAddMassHours"
                                    >
                                      <img
                                        src={iconMultiEdit}
                                        alt="multi_edit"
                                      />
                                    </button>
                                  </Tooltip>
                                  <Tooltip text="Удалить почасовку">
                                    <button
                                      type="button"
                                      className={styles.manageBtn}
                                      onClick={handlePresaveChange("delete")}
                                      id="AregisterAllReportNewReportTableHeader_deletePresave"
                                    >
                                      <IconBasket
                                        alt="delete_presave"
                                        className={styles.iconBasket__red}
                                      />
                                    </button>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          ) : (
                            <div>{getFormattedDate(presave[title])}</div>
                          )}
                        </AregisterAllReportNewReportTableCell>
                      </Fragment>
                    );

                  case "date_start":
                    return (
                      <Fragment key={`date_${presave.id}`}>
                        {!presaveIndex && (
                          <AregisterAllReportNewReportTableHeaderCell
                            tableCols={tableCols}
                            title={title}
                          />
                        )}
                        <AregisterAllReportNewReportTableCell
                          className={classNames(styles.presaveCell, {
                            [styles.hiddenColumn]:
                              columnNumber !== null &&
                              columnNumber !== presaveIndex,

                            [styles.presaveCell__active]: !closed || !canceled
                          })}
                          column={presaveIndex}
                          highlightedColumn={highlightedColumn}
                          setHighlightedColumn={setHighlightedColumn}
                        >
                          <DatePickerField
                            name={`date_start_${presave.id}`}
                            isCalendarOpened={
                              openedWindow === `date_start_${presave.id}`
                            }
                            setIsCalendarOpened={() => {
                              changeOpenedWindow(`date_start_${presave.id}`);
                            }}
                            title=" "
                            disabled={canceled || closed}
                            className={styles.datePickerCustom}
                          />
                        </AregisterAllReportNewReportTableCell>
                      </Fragment>
                    );

                  case "title":
                    return (
                      <Fragment key={`title_${presave.id}`}>
                        {!presaveIndex && (
                          <AregisterAllReportNewReportTableHeaderCell
                            tableCols={tableCols}
                            title={title}
                          />
                        )}
                        <AregisterAllReportNewReportTableCell
                          className={classNames(styles.presaveCell, {
                            [styles.hiddenColumn]:
                              columnNumber !== null &&
                              columnNumber !== presaveIndex,
                            [styles.presaveCell__active]: !closed || !canceled
                          })}
                          column={presaveIndex}
                          highlightedColumn={highlightedColumn}
                          setHighlightedColumn={setHighlightedColumn}
                        >
                          {aregister[title] ? (
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <Tooltip
                                  text={`${
                                    closed
                                      ? "Заявка закрыта"
                                      : canceled
                                      ? "Заявка аннулирована"
                                      : "Заявка открыта"
                                  }`}
                                >
                                  <img
                                    src={
                                      closed
                                        ? iconLock
                                        : canceled
                                        ? iconCancel
                                        : iconLockOpen
                                    }
                                    className={styles.iconStatus}
                                  />
                                </Tooltip>
                                <div
                                  className={styles.internalNum}
                                  id={`AregisterAllReportNewReportTableHeader_to_Aregister_${presave.aregister}`}
                                  onClick={handlePresaveChange("nav")}
                                >
                                  {aregister[title] ? aregister[title] : ""}
                                </div>
                              </div>
                              {closed || canceled ? (
                                ""
                              ) : (
                                <button
                                  type="button"
                                  className={styles.iconClose}
                                  onClick={handlePresaveChange("unpin")}
                                  id="AregisterAllReportNewReportTableHeader_unpinPresave"
                                >
                                  <StatusIcon
                                    icon="iconclose"
                                    color="bw-gray5"
                                  />
                                </button>
                              )}
                            </div>
                          ) : (
                            <div
                              className={classNames(
                                styles.select,
                                {
                                  [styles.select__disabled]: canceled || closed
                                },
                                [styles.select]
                              )}
                            >
                              <Select
                                options={
                                  aregisterReportStore.aregisterTitlesList
                                }
                                name={`aregister_${presave.id}_${presaveIndex}`}
                                title={
                                  aregister[title]
                                    ? `${aregister[title]}_${presaveIndex}`
                                    : "Заявка"
                                }
                                disabled={canceled || closed}
                                valueName="id"
                                onChange={hadleChangePresavePin}
                              />
                            </div>
                          )}
                        </AregisterAllReportNewReportTableCell>
                      </Fragment>
                    );

                  case "master_fio":
                  case "brigadier_fio":
                    return (
                      <Fragment key={presave.id}>
                        {!presaveIndex && (
                          <AregisterAllReportNewReportTableHeaderCell
                            tableCols={tableCols}
                            title={title}
                          />
                        )}
                        <AregisterAllReportNewReportTableCell
                          className={classNames(styles.presaveCell, {
                            [styles.hiddenColumn]:
                              columnNumber !== null &&
                              columnNumber !== presaveIndex,
                            [styles.presaveCell__active]: !closed || !canceled
                          })}
                          column={presaveIndex}
                          highlightedColumn={highlightedColumn}
                          setHighlightedColumn={setHighlightedColumn}
                        >
                          <div className="d-flex justify-content-between">
                            <div
                              className={classNames(
                                styles.select,
                                {
                                  [styles.select__disabled]: canceled || closed
                                },
                                [styles.select]
                              )}
                            >
                              <Select
                                options={
                                  title === "master_fio"
                                    ? aregisterReportStore.mastersList
                                    : aregisterReportStore.brigadiersList
                                }
                                name={`${aregister[title]}_${presaveIndex}`}
                                title={aregister[title]}
                                disabled={canceled || closed}
                                valueName="id"
                              />
                            </div>
                            {(aregister[title] && closed) || canceled ? (
                              ""
                            ) : (
                              <button
                                type="button"
                                className={styles.iconClose}
                                disabled={canceled || closed}
                              >
                                <StatusIcon icon="iconclose" color="bw-gray5" />
                              </button>
                            )}
                          </div>
                        </AregisterAllReportNewReportTableCell>
                      </Fragment>
                    );

                  case "temporary_num":
                    return (
                      <Fragment key={`temp_${presave.id}`}>
                        {!presaveIndex && (
                          <AregisterAllReportNewReportTableHeaderCell
                            tableCols={tableCols}
                            title={title}
                          />
                        )}
                        <AregisterAllReportNewReportTableCell
                          className={classNames(styles.presaveCell, {
                            [styles.hiddenColumn]:
                              columnNumber !== null &&
                              columnNumber !== presaveIndex,
                            [styles.presaveCell__active]: !closed || !canceled
                          })}
                          column={presaveIndex}
                          highlightedColumn={highlightedColumn}
                          setHighlightedColumn={setHighlightedColumn}
                        >
                          <span className={styles.temporaryNum}>
                            <input
                              name={`temporary_num_${presave.id}`}
                              value={
                                values[`temporary_num_${presave.id}`] || ""
                              }
                              onChange={handleChangeTempNum}
                              onBlur={handlePresaveChange("update")}
                              id={`temporary_num_${presave.id}`}
                              className={styles.temporaryNum__input}
                              disabled={canceled || closed}
                            />
                            <label htmlFor={`temporary_num_${presave.id}`}>
                              {closed || canceled ? (
                                ""
                              ) : (
                                <button
                                  type="button"
                                  className={styles.iconClose}
                                  disabled={canceled || closed}
                                >
                                  <StatusIcon
                                    icon="iconclose"
                                    color="bw-gray5"
                                  />
                                </button>
                              )}
                            </label>
                          </span>
                        </AregisterAllReportNewReportTableCell>
                      </Fragment>
                    );
                }
              }
            )}
            <th
              className={classNames(
                styles.total,
                {
                  [styles.total__shadow]: menuStore.scroll["scrollLeft"]
                },
                [styles.total__white]
              )}
            >
              {title === "temporary_num" ? (
                <Tooltip text="Сумма часов из заявок / сумма часов из смен">
                  <IconQuestionSmall
                    className={styles.iconQuestion}
                    alt="tooltip"
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </th>
          </tr>
        );
      })}
    </thead>
  );
};
export default observer(AregisterAllReportNewReportTableHeader);
