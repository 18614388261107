import styles from "./staffOneConvictionWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";

import { useState, useEffect, Fragment } from "react";

import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { format, getYear, parse } from "date-fns";
import { validationStaffOneConvitionForm } from "./validation";

import { Input } from "shared/ui/Inputs/Input";

import Textarea from "shared/ui/Inputs/Textarea";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Conviction } from "stores/StaffModule/types/Conviction";

import FormWindow from "shared/ui/FormWindow";
import Select from "shared/ui/Inputs/Select";

import { ReactComponent as IconLeft } from "shared/assets/images/mainIcons/iconLeft/iconLeftWithoutStroke.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ViewField } from "shared/ui/ViewField";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneConvictionWindowProps = {
  type: string;
  id: string;
  activeRow: Partial<Conviction>;
  setActiveRow: (el: Partial<Conviction>) => void;
  setOpenWindow: (el: { type: string; open: boolean }) => void;
};

const StaffOneConvictionWindow = ({
  id,
  type,
  setOpenWindow,
  activeRow,
  setActiveRow
}: StaffOneConvictionWindowProps) => {
  const { staffOneConvictionStore } = useStores();
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string | number;
  }>({});
  const [openedName, setOpenedName] = useState("");

  useEffect(() => {
    const newConvictionObject = {};
    Object.entries(activeRow).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        switch (key) {
          case "category":
            newConvictionObject[key] = value["title"] || "";
            break;
          default:
            newConvictionObject[key] = value["showname"] || value["id"] || "";
        }
      } else newConvictionObject[key] = value || "";
    });

    switch (type) {
      case "detail":
      case "edit":
        setInitialValues(newConvictionObject);
        break;
      case "add":
        setInitialValues(getInitialValues());
        break;
      default:
        break;
    }
  }, [type]);

  const getInitialValues = () => {
    const newInitialValues = {};

    Object.keys(staffOneConvictionStore.cols).map((field) => {
      switch (field) {
        case "id":
          newInitialValues[field] = id;
          break;
        case "time_create":
          newInitialValues[field] = format(new Date(), "yyyy-MM-dd");
          break;
        case "comment":
          newInitialValues[field] = "";
          break;
        default:
          switch (staffOneConvictionStore.cols[field].type) {
            case "bool":
              newInitialValues[field] = 0;
              break;
            default:
              newInitialValues[field] = "";
              break;
          }
          break;
      }
    });

    return newInitialValues;
  };

  const changeOpened = (name: string) => {
    openedName === name ? setOpenedName("") : setOpenedName(name);
  };

  return (
    <>
      {Object.values(initialValues).length ? (
        <div className={styles.windowContainer}>
          <div className={styles.window}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationStaffOneConvitionForm}
              onSubmit={(values) => {
                type === "add" &&
                  staffOneConvictionStore.addConviction(
                    id,
                    values.article as string,
                    values.date_start as string,
                    values.punishment as string,
                    values.term_type as string,
                    values.comment as string
                  );
                type === "edit" &&
                  staffOneConvictionStore.editConviction(
                    id,
                    values.id as string,
                    values.article as string,
                    values.date_start as string,
                    values.punishment as string,
                    values.term_type as string,
                    values.comment as string
                  );
                setOpenWindow({ type: "", open: false });
              }}
              enableReinitialize
            >
              {({
                values,
                setFieldTouched,
                setFieldValue,
                handleSubmit,
                handleChange,
                isValid,
                dirty
              }) => {
                return (
                  <FormWindow
                    title={(() => {
                      switch (type) {
                        case "detail":
                          return "Просмотр записи";
                        case "edit":
                          return "Редактирование записи";
                        case "add":
                          return "Добавление записи";
                      }
                    })()}
                    setOpenWindow={() =>
                      setOpenWindow({ type: "", open: false })
                    }
                    saveBtnTitle={
                      type === "add" || type === "edit"
                        ? "Сохранить"
                        : "Редактировать"
                    }
                    saveBtnOnClickFunc={() => {
                      type === "detail"
                        ? setOpenWindow({ type: "edit", open: true })
                        : handleSubmit();
                    }}
                    saveBtnDisabledValue={
                      (type === "add" || type === "edit") &&
                      (!isValid || !dirty)
                    }
                    addBtnTitle={
                      type === "add" ? "Отмена" : type === "edit" ? "Назад" : ""
                    }
                    addBtnOnClickFunc={() => {
                      type === "edit" &&
                        setOpenWindow({ type: "detail", open: true });
                    }}
                    addBtnImg={
                      type === "edit" ? (
                        <IconLeft className={styles.backIcon} />
                      ) : type === "add" ? (
                        <IconClose />
                      ) : null
                    }
                    hasBackBtn={type === "edit"}
                    optionalCloseFunc={() => {
                      setActiveRow({});
                      setOpenWindow({ type: "", open: false });
                    }}
                    isScroll
                  >
                    <>
                      <Form className={styles.form}>
                        {Object.values(
                          staffOneConvictionStore.showFields[type]
                        ).map((field) => {
                          switch (field) {
                            case "time_create":
                            case "author":
                              if (
                                (type === "edit" || type === "add") &&
                                field === "author"
                              )
                                return;
                              else
                                return (
                                  <div
                                    key={field}
                                    className={
                                      type === "detail"
                                        ? styles.disabledField
                                        : ""
                                    }
                                  >
                                    <ViewField
                                      title={
                                        staffOneConvictionStore.cols[field][
                                          "title"
                                        ]
                                      }
                                      value={getFormattedDate(
                                        getFormattedDate(String(values[field]))
                                      )}
                                      disabled={
                                        field === "time_create" &&
                                        (type === "edit" || type === "add")
                                      }
                                    />
                                  </div>
                                );
                            case "article":
                            case "punishment":
                            case "term_type":
                              return (
                                <Fragment key={field}>
                                  <div
                                    className={
                                      type === "detail"
                                        ? styles.disabledField
                                        : ""
                                    }
                                  >
                                    {type === "detail" ? (
                                      <ViewField
                                        title={
                                          staffOneConvictionStore.cols[field]
                                            .title
                                        }
                                        value={
                                          field === "article"
                                            ? staffOneConvictionStore.cols[
                                                field
                                              ]["directory"][values[field]]?.[
                                                "title"
                                              ] ||
                                              staffOneConvictionStore.cols[
                                                field
                                              ]["directory"][
                                                `${values[field]}`
                                              ]?.["title"] ||
                                              `ст.${
                                                activeRow[field]?.["number"]
                                              } ${
                                                activeRow[field]?.["part"]
                                                  ? `ч.${activeRow[field]?.["part"]}`
                                                  : ""
                                              } ${
                                                activeRow[field]?.["title"]
                                              }` ||
                                              ""
                                            : String(values[field])
                                        }
                                      />
                                    ) : (
                                      <Select
                                        required={field === "article"}
                                        name={field}
                                        title={
                                          staffOneConvictionStore.cols[field]
                                            .title
                                        }
                                        isFloating
                                        label={
                                          values[field]?.["title"]
                                            ? values[field]["title"]
                                            : ""
                                        }
                                        options={
                                          staffOneConvictionStore.cols[field][
                                            "directory"
                                          ]
                                        }
                                        onClick={(option) => {
                                          if (option) {
                                            setFieldTouched(field, true);
                                            if (field === "article") {
                                              setFieldValue(
                                                "article",
                                                option.newname
                                              );
                                              setFieldValue(
                                                "category",
                                                option["category"]
                                              );
                                            } else {
                                              setFieldValue(
                                                field,
                                                option.title
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                  {field === "article" && type === "detail" && (
                                    <div className={styles.disabledField}>
                                      <ViewField
                                        title="Категория"
                                        value={
                                          staffOneConvictionStore.cols[field][
                                            "directory"
                                          ][values[field]]?.["category"]
                                        }
                                      />
                                    </div>
                                  )}
                                </Fragment>
                              );
                            case "date_start":
                              return (
                                <div
                                  key={field}
                                  className={
                                    type === "detail"
                                      ? styles.disabledField
                                      : ""
                                  }
                                >
                                  {type === "detail" ? (
                                    <ViewField
                                      title={
                                        staffOneConvictionStore.cols[field][
                                          "title"
                                        ]
                                      }
                                      value={getFormattedDate(
                                        String(values[field])
                                      )}
                                    />
                                  ) : (
                                    <DatePickerField
                                      name={field}
                                      dateFormat={"yyyy"}
                                      title={
                                        staffOneConvictionStore.cols[field][
                                          "title"
                                        ]
                                      }
                                      isCalendarOpened={openedName === field}
                                      setIsCalendarOpened={() => {
                                        changeOpened(field);
                                      }}
                                      minDate={parse(
                                        String(getYear(new Date()) - 70 + 14),
                                        "yyyy",
                                        new Date()
                                      )}
                                      required
                                    />
                                  )}
                                </div>
                              );
                            case "comment":
                              return (
                                <div
                                  key={field}
                                  className={
                                    type === "detail"
                                      ? styles.disabledField
                                      : ""
                                  }
                                >
                                  <Textarea
                                    name={field}
                                    value={
                                      (values[field]?.["title"]
                                        ? values[field]["title"]
                                        : values[field]) || ""
                                    }
                                    placeholder={
                                      staffOneConvictionStore.cols[field][
                                        "title"
                                      ]
                                    }
                                    label={
                                      staffOneConvictionStore.cols[field][
                                        "title"
                                      ]
                                    }
                                    disabled={
                                      !(
                                        type !== "detail" &&
                                        field.includes("comment")
                                      )
                                    }
                                    commentCol={
                                      field === "comment"
                                        ? staffOneConvictionStore.cols[field]
                                        : ""
                                    }
                                  />
                                </div>
                              );
                            case "uid":
                            case "id":
                              return;
                            default:
                              if (staffOneConvictionStore.cols[field]) {
                                return (
                                  <div
                                    className={
                                      type === "detail"
                                        ? styles.disabledField
                                        : ""
                                    }
                                    key={field}
                                  >
                                    <Input
                                      disabled={
                                        type === "detail" ||
                                        field === "category"
                                      }
                                      name={field}
                                      label={
                                        staffOneConvictionStore.cols[field][
                                          "title"
                                        ]
                                      }
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                  </div>
                                );
                              } else return;
                          }
                        })}
                      </Form>
                    </>
                  </FormWindow>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default observer(StaffOneConvictionWindow);
