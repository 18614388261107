import styles from "./truddogTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { ICompany } from "stores/StaffModule/types/RecordsForEdit";
import { useParams } from "react-router-dom";
import { getEntries } from "shared/utils/helpers/getEntries";
import { Button, ButtonTheme } from "shared/ui/Button";

type TruddogTabsProps = {
  values: { companies: Record<string, ICompany>; company: string[] };
};

const TruddogTabs = ({ values }: TruddogTabsProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams();

  const employments =
    values.companies?.[staffOneEditStore.selectedCompanyId[id]]?.employments;

  const truddogSorted = getEntries(employments).sort((a, b) => {
    const firstDate = a[1].trud_dog.start_date;
    const secondDate = b[1].trud_dog.start_date;
    if (firstDate === secondDate) return 0;
    else if (firstDate < secondDate) return 1;
    else return -1;
  });

  const handleOnClickBtn = (key: string) => () =>
    staffOneEditStore.setSelectedTypeOfJob(id, key);

  return (
    <div className={styles.tabsContainer}>
      {truddogSorted.map(([key, values]) => (
        <Button
          key={key}
          theme={ButtonTheme.TAB}
          id={`TruddogTabs_selectJob_${key}`}
          onClick={handleOnClickBtn(key)}
          selected={staffOneEditStore.selectedTypeOfJob[id] === key}
          label={values.employment_title || " "}
        >
          {`${key} ${values.status || ""}`}
        </Button>
      ))}
    </div>
  );
};

export default observer(TruddogTabs);
