import styles from "./buildingOneStaffMovements.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import SearchInput from "shared/ui/Inputs/SearchInput";
import BuildingOneStaffMovementsTable from "./BuildingOneStaffMovementsTable";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const BuildingOneStaffMovements = () => {
  const { id } = useParams();
  const { modalWindowsStore, menuStore, buildingOneStaffMovementsStore } =
    useStores();
  const inputRef = useRef<HTMLInputElement>();

  const handleFindData = () => {
    buildingOneStaffMovementsStore.setSearchValue(
      buildingOneStaffMovementsStore.searchValue
    );
    buildingOneStaffMovementsStore.getStaffMovementsList();
    inputRef.current.blur();
  };

  useEffect(() => {
    buildingOneStaffMovementsStore.setSelectedBuilding(id);
    menuStore.sendTabId(id);
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("buildingOne");
    menuStore.setOpenedSubmodule("buildingOneStaffMovements");
    menuStore.updateWindow({
      mainPath: `/building/id=${id}`,
      path: `/building/id=${id}/staffmovements`
    });
  }, [id]);

  useEffect(() => {
    if (
      buildingOneStaffMovementsStore.page !== 1 &&
      buildingOneStaffMovementsStore.page <=
        buildingOneStaffMovementsStore.maxPage &&
      buildingOneStaffMovementsStore.page !==
        buildingOneStaffMovementsStore.prevPage
    ) {
      buildingOneStaffMovementsStore.getMoreStaffMovements();
    }
  }, [
    buildingOneStaffMovementsStore.page,
    buildingOneStaffMovementsStore.maxPage
  ]);

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>
          {buildingOneStaffMovementsStore.selectedBuilding["title"]}
        </p>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SearchInput
            inputRef={inputRef}
            onChange={(e) => {
              buildingOneStaffMovementsStore.setSearchValue(e.target.value);
            }}
            value={buildingOneStaffMovementsStore.searchValue}
            handleFindData={handleFindData}
            blurCondition={
              buildingOneStaffMovementsStore.isLoading ||
              modalWindowsStore.isErrorWindow
            }
            clearSearch={() => {
              buildingOneStaffMovementsStore.setSearchValue("");
              buildingOneStaffMovementsStore.getStaffMovementsList();
            }}
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BuildingOneStaffMovementsTable />
      </ErrorBoundary>
    </>
  );
};

export default observer(BuildingOneStaffMovements);
