import { classNames } from "shared/utils/helpers/classNames";
import styles from "./buttonsTabs.module.scss";
import { Button, ButtonTheme } from "shared/ui/Button";

type ButtonsTabs = {
  tabs: { title: string; id: string }[];
  changeActiveTab: (id: string) => void;
  className?: Partial<{
    container: string;
    allTabs: string;
    tab: string;
  }>;
  title?: string;
};

type WithSelectedTab = ButtonsTabs & {
  selectedTab: string;
  customSelectedTab?: never;
};

type WithCustomSelectedTab = ButtonsTabs & {
  selectedTab?: never;
  customSelectedTab: (tabId: string) => string;
};

type ButtonsTabsProps = WithSelectedTab | WithCustomSelectedTab;

const ButtonsTabs = ({
  selectedTab,
  customSelectedTab,
  tabs,
  changeActiveTab,
  title,
  className
}: ButtonsTabsProps) => {
  return (
    <div className={classNames("", {}, [className?.container || ""])}>
      {title ? <p className={styles.title}>{title}</p> : null}
      <div className={classNames(styles.tabs, {}, [className?.allTabs || ""])}>
        {tabs.map((item) => {
          const handleClick = () => {
            changeActiveTab(item.id);
          };

          return item.id !== undefined ? (
            <Button
              theme={ButtonTheme.TAB}
              key={item.id}
              onClick={handleClick}
              id={`ButtonsTabs_${item.id}`}
              className={classNames(styles.button, {}, [className?.tab || ""])}
              selected={
                (customSelectedTab
                  ? customSelectedTab(item.id)
                  : selectedTab) === item.id
              }
            >
              {item.title}
            </Button>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default ButtonsTabs;
