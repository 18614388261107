import styles from "./searchInput.module.scss";
import { useState } from "react";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconClose.svg";
import iconSearch from "shared/assets/images/mainIcons/iconSearch.svg";
import iconSearchActive from "shared/assets/images/mainIcons/iconSearchActive.svg";

type SearchInputProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  clearSearch: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  handleFindData?: () => void;
  blurCondition?: boolean;
  autocomplete?: "off";
  withoutSearchButton?: boolean;
  fromList?: boolean;
};

const SearchInput = ({
  placeholder,
  value,
  onChange,
  onBlur,
  clearSearch,
  inputRef,
  handleFindData,
  blurCondition,
  autocomplete,
  withoutSearchButton,
  fromList
}: SearchInputProps) => {
  const [showClose, toggleShowClose] = useState(false);
  const handleOnClickIconSearch = () => showClose && value && handleFindData();
  const handleOnFocusInput = () => toggleShowClose(true);
  const handleOnClickBtn = () => handleFindData && handleFindData();
  const handleOnClickClearBtn = () => {
    clearSearch();
    toggleShowClose(false);
  };
  const handleOnKeyPress = (type: string) => (event: React.KeyboardEvent) => {
    const actions = {
      Enter: () => handleFindData(),
      Escape: () => clearSearch()
    };
    actions[event.key === type ? type : ""]?.();
    blurCondition && inputRef.current.blur();
  };
  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    onBlur && onBlur(event);
    setTimeout(() => toggleShowClose(false), 200);
  };

  return (
    <div
      className={styles.searchWrapper}
      data-type={fromList ? "list-search" : ""}
    >
      <img
        src={showClose ? iconSearchActive : iconSearch}
        onClick={handleOnClickIconSearch}
        className={styles.iconSearch}
        id="Searchinput_findByIconSearch"
        data-type={fromList ? "list-search" : ""}
      />
      <input
        ref={inputRef}
        className={styles.search}
        name="search"
        type="text"
        placeholder={placeholder || "Быстрый поиск"}
        value={value}
        onChange={onChange}
        onKeyPress={handleOnKeyPress("Enter")}
        onKeyDown={handleOnKeyPress("Escape")}
        onFocus={handleOnFocusInput}
        autoComplete={autocomplete || "on"}
        onBlur={handleOnBlur}
        data-type={fromList ? "list-search" : "search"}
      />
      {showClose ? (
        <div
          className={styles.btnsGroup}
          data-type={fromList ? "list-search" : ""}
        >
          {!withoutSearchButton && value ? (
            <div
              className={styles.findBtn}
              id="Searchinput_findData"
              onClick={handleOnClickBtn}
              data-type={fromList ? "list-search" : ""}
            >
              Найти
            </div>
          ) : null}

          <div
            className={styles.closeBtn}
            id="Searchinput_clearSearch"
            onClick={handleOnClickClearBtn}
            data-type={fromList ? "list-search" : ""}
          >
            <IconClose data-type={fromList ? "list-search" : ""} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
