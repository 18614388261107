import styles from "./headerModulesMenu.module.scss";
import { observer } from "mobx-react-lite";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { useStores } from "stores/index";

import Masonry from "react-masonry-css";
import Scrollbars from "react-custom-scrollbars-2";
import SearchInput from "shared/ui/Inputs/SearchInput";
import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";

import Errors from "shared/assets/images/menuIcons/iconDefault/Errors.svg";

import { fileUrl } from "stores/utils/consts";
import { modulesListType } from "stores/menu";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { icons } from "./icons";
import { maxHeight } from "shared/utils/constants/maxHeight";

type HeaderModulesMenuProps = {
  path: string;
};

const HeaderModulesMenu = ({ path }: HeaderModulesMenuProps) => {
  const [openedModule, setOpenedModule] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [breakpointColumns, setBreakpointColumns] = useState<{
    [key: string]: number;
  }>({});
  const [foundedModules, setFoundedModules] = useState<modulesListType>({});

  const { menuStore } = useStores();
  const ref = useRef();

  useEffect(() => {
    menuStore.selectedWindow !== "all" && openedModule && setOpenedModule("");
    if (menuStore.selectedWindow === "all") {
      setSearchValue("");
      Object.values(menuStore.modulesList).forEach((module) => {
        if (menuStore.allWindows[`/${module.dir}`]) {
          setOpenedModule(module.dir);
        }
      });
    }
  }, [menuStore.selectedWindow]);

  useEffect(() => {
    if (searchValue.length) {
      const foundedModulesList: modulesListType = {};

      Object.values(menuStore.modulesList).forEach((module) => {
        if (module.title.toLowerCase().includes(searchValue.toLowerCase())) {
          foundedModulesList[module.id] = module;
        }
      });

      setFoundedModules(foundedModulesList);
    } else {
      setFoundedModules({});
    }
  }, [searchValue]);

  useEffect(() => {
    if (menuStore.modulesList[openedModule]?.sections) {
      let length = 0;

      Object.values(menuStore.modulesList[openedModule].sections).forEach(
        (section) => {
          if (section.submodules) {
            length += Object.values(section.submodules).length;
          }
        }
      );

      switch (true) {
        case length <= 7:
          return setBreakpointColumns({
            default: 1
          });
        case length <= 14:
          return setBreakpointColumns({
            default: 2,
            900: 1
          });
        default:
          return setBreakpointColumns({ default: 3, 1100: 2, 900: 2 });
      }
    }
  }, [openedModule]);

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (
        event.target["className"]?.length &&
        !event.target["className"].includes("headerTopMenu") &&
        (path === "" || path !== "/main")
      ) {
        menuStore.setSelectedWindow(path);
      }
    }
  });

  return (
    <div
      className={`${styles.windowContainer} ${
        menuStore.selectedWindow !== "all" ? styles.windowContainer_close : ""
      } accordion`}
      tabIndex={-1}
      onKeyUp={(event) => {
        if (event.key === "Escape") {
          if (path === "" || path !== "/main") {
            menuStore.setSelectedWindow(path);
          }
        }
      }}
    >
      <div className={styles.headerContainer} ref={ref}>
        <div className={styles.header}>
          <div className={styles.header__title}>
            <div>Все разделы</div>
            <div className={styles.searchInput}>
              <SearchInput
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                clearSearch={() => {
                  setSearchValue("");
                }}
                placeholder="Поиск по разделам"
              />
            </div>
          </div>

          {!(path === "" || path === "/main") ? (
            <div
              id={`HeaderModulesMenu_CloseIcon`}
              className={styles.closeButton}
              onClick={() => {
                menuStore.setSelectedWindow(path);
                setOpenedModule("");
              }}
            >
              <StatusIcon icon="iconclose" color="bw-gray5" />
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className={`${styles.modulesContainer} ${
            openedModule?.length &&
            menuStore.modulesList[openedModule]?.sections
              ? styles.modulesContainer_withSubmodule
              : ""
          }`}
        >
          <Scrollbars
            width="max-content"
            autoHeight
            autoHeightMax={`calc(${maxHeight} - 220px)`}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            <div className={styles.leftCol}>
              {(!searchValue.length &&
                Object.values(menuStore.modulesList).length) ||
              (searchValue.length && Object.values(foundedModules).length) ? (
                Object.values(
                  searchValue.length ? foundedModules : menuStore.modulesList
                ).map((module) => {
                  if (
                    "link" in module &&
                    !menuStore.closedModules.includes(module.dir)
                  ) {
                    return (
                      <div key={module.id} className={styles.moduleContainer}>
                        <Tooltip
                          text={`Перейти в модуль ${module.title} текущего ПО`}
                          placement="right"
                          color="blue-lazure"
                        >
                          <a
                            className={styles.module__link}
                            rel="noreferrer"
                            target="_blank"
                            href={`${fileUrl}${module.link}`}
                          >
                            <div className={styles.module}>
                              <div className={styles.module__title}>
                                {module.title}
                              </div>
                            </div>
                          </a>
                        </Tooltip>
                      </div>
                    );
                  } else if (menuStore.closedModules.includes(module.dir)) {
                    return (
                      <div key={module.id} className={styles.moduleContainer}>
                        <Tooltip
                          text={`Раздел ${module.title} находится в разработке`}
                          placement="right"
                          color="blue-lazure"
                        >
                          <div
                            className={`${styles.module} ${styles.module_disabled}`}
                          >
                            <div className={styles.module__title}>
                              {module.title}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        id={`HeaderModulesMenu_${module.id}`}
                        key={module.id}
                        className={`${styles.module}
                             
                            ${
                              openedModule === module.dir
                                ? styles.module_selected
                                : ""
                            }
                          `}
                        onClick={() => {
                          setOpenedModule(
                            openedModule !== module.dir ? module.dir : ""
                          );
                        }}
                      >
                        <div
                          id={`HeaderModulesMenu_${module.dir}`}
                          className={styles.module__title}
                          onClick={() => {
                            openedModule === module.dir && setOpenedModule("");
                          }}
                        >
                          {module.title}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div className={styles.noModules}>Ничего не найдено</div>
              )}
            </div>
          </Scrollbars>

          {openedModule?.length &&
          menuStore.modulesList[openedModule]?.sections ? (
            <div className={styles.scrollbarForColumn}>
              <Scrollbars
                width="max-content"
                autoHeight
                autoHeightMax={`calc(${maxHeight} - 220px)`}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
              >
                <Masonry
                  breakpointCols={breakpointColumns}
                  className={styles.columnContainer}
                  columnClassName={styles.column}
                >
                  {Object.values(
                    menuStore.modulesList[openedModule].sections
                  ).map((section) => {
                    return section?.submodules
                      ? Object.values(section.submodules).map((submodule) => {
                          const module = menuStore.modulesList[openedModule];

                          if (
                            menuStore.closedSubModules.find(
                              (item) =>
                                openedModule in item &&
                                item[openedModule].includes(submodule.dir)
                            )
                          ) {
                            return (
                              <Tooltip
                                key={submodule.id}
                                placement="right"
                                color="blue-lazure"
                                text={`Раздел ${submodule.title} находится в разработке`}
                              >
                                <div className={styles.submodule}>
                                  <div className={styles.submodule__title}>
                                    <img
                                      src={
                                        icons[module.dir]?.[submodule.dir] ||
                                        Errors
                                      }
                                    />
                                    <p>{submodule.title}</p>
                                  </div>
                                  {/* При подключении API тут будет описание подмодуля */}
                                  <div> </div>
                                </div>
                              </Tooltip>
                            );
                          }

                          // Так как фронт пока не знает, какие подразделы должны открывать старый интерфейс, а какие новый
                          // то пока для нужного подраздела в файле /utils/dictionaries/allModules.ts хардкодом прописываем поле fullstack с ссылкой на старый интерфейс
                          // и эта часть кода уже проверяет, если поле fullstack есть, то происходит редирект в старый интерфейс
                          if (submodule["fullstack"]) {
                            return (
                              <Tooltip
                                key={submodule.id}
                                placement="right"
                                color="blue-lazure"
                                text={`Перейти в раздел ${submodule.title} текущего ПО`}
                              >
                                <div
                                  className={styles.submodule}
                                  onClick={() =>
                                    window.open(
                                      submodule["fullstack"],
                                      "_blank"
                                    )
                                  }
                                  id={`HeaderModulesMenu_to_fullstack_${submodule.id}`}
                                >
                                  <div className={styles.submodule__title}>
                                    <img
                                      src={
                                        icons[module.dir]?.[submodule.dir] ||
                                        Errors
                                      }
                                    />
                                    <p>{submodule.title}</p>
                                  </div>
                                </div>
                              </Tooltip>
                            );
                          }

                          if (
                            !Object.values(menuStore.allWindows).length ||
                            menuStore.allWindows[`/${module.dir}`]
                          ) {
                            return (
                              <Link
                                to={`/${module.dir}/${submodule.dir}`}
                                id={`HeaderModulesMenu_${submodule.id}`}
                                key={submodule.id}
                                className={styles.submodule}
                                onClick={() => {
                                  if (menuStore.allWindows[`/${module.dir}`]) {
                                    menuStore.setSelectedWindow(
                                      `/${module.dir}`
                                    );
                                  }
                                }}
                              >
                                <div className={styles.submodule__title}>
                                  <img
                                    src={
                                      icons[module.dir]?.[submodule.dir] ||
                                      Errors
                                    }
                                  />
                                  <p>{submodule.title}</p>
                                </div>
                                {/* При подключении API тут будет описание подмодуля */}
                                <div className={styles.submodule__text}> </div>
                              </Link>
                            );
                          } else {
                            return (
                              <Link
                                to={`/${module.dir}/${submodule.dir}`}
                                id={`HeaderModulesMenu_${submodule.id}`}
                                key={submodule.id}
                                target="_blank"
                                className={styles.submodule}
                                onClick={() => {
                                  setOpenedModule("");
                                }}
                              >
                                <div className={styles.submodule__title}>
                                  <img
                                    src={
                                      icons[module.dir]?.[submodule.dir] ||
                                      Errors
                                    }
                                  />
                                  <p>{submodule.title}</p>
                                </div>
                                {/* При подключении API тут будет описание подмодуля */}
                                <div> </div>
                              </Link>
                            );
                          }
                        })
                      : "";
                  })}
                </Masonry>
              </Scrollbars>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
export default observer(HeaderModulesMenu);
