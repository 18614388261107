import { useEffect, useState } from "react";
import styles from "./salaryPremiumsOneInfoStaffTableFilter.module.scss";
import SearchInput from "shared/ui/Inputs/SearchInput";
import Tooltip from "shared/ui/Tooltip";
import Select from "shared/ui/Inputs/Select";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";

const filters = [
  { title: "Все", id: "Все" },
  { title: "Не ИТР", id: "Не ИТР" },
  { title: "ИТР", id: "ИТР" }
];

const options = {
  0: {
    title: "Мастер",
    newname: "master"
  },
  1: {
    title: "Монтажник",
    newname: "monter"
  },
  2: {
    title: "Инженер",
    newname: "engineer"
  }
};

const SalaryPremiumsOneInfoStaffTableFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState("");

  useEffect(() => {
    setSelectedFilter(filters[0].id);
  }, []);

  return (
    <div className={styles.mainBlock}>
      <div className={styles.columnBlock}>
        <p>Сотрудники</p>
        <ButtonsTabs
          tabs={filters}
          selectedTab={selectedFilter}
          changeActiveTab={(id) =>
            selectedFilter !== id && setSelectedFilter(id)
          }
        />
      </div>
      <div className={styles.columnBlock}>
        <p>ФИО</p>
        <Tooltip
          color="blue-lazure"
          text="Возможность поиска находится в разработке"
        >
          <div className={styles.searchBlock}>
            <SearchInput
              placeholder="Поиск по ФИО"
              onChange={() => {
                return;
              }}
              value={""}
              handleFindData={() => {
                return;
              }}
              clearSearch={() => {
                return;
              }}
            />
          </div>
        </Tooltip>
      </div>
      <div className={styles.columnBlock}>
        <p>Должность</p>
        <Tooltip
          color="blue-lazure"
          text="Возможность поиска находится в разработке"
        >
          <div className={styles.select}>
            <Select name="position" options={options} title="Должность" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SalaryPremiumsOneInfoStaffTableFilter;
