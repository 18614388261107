import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import StatusIcon from "shared/ui/StatusIcon";
import { useStores } from "stores";
import styles from "./safetyWorkListTable.module.scss";
import BigCheck from "shared/assets/images/iconStatus/BigCheck.svg";
import IconAttention from "shared/assets/images/iconStatus/Attention.svg";
import Hourglass from "shared/assets/images/iconStatus/Hourglass.svg";
import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import { browserName } from "react-device-detect";
import SafetyWorkCertWindow from "./SafetyWorkCertWindow";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type SafetyWorkListTableProps = {
  filterList: (arg: string) => void;
};

const SafetyWorkListTable = ({ filterList }: SafetyWorkListTableProps) => {
  const { safetyWorkListStore, filterStore, menuStore } = useStores();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      safetyWorkListStore.maxPage >= safetyWorkListStore.page &&
      safetyWorkListStore.page === safetyWorkListStore.prevPage
    ) {
      safetyWorkListStore.setPage(safetyWorkListStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {Object.values(safetyWorkListStore.safetyWorkCols).length &&
      safetyWorkListStore.currentTitles.length ? (
        <Table
          className={styles.table}
          style={{
            marginTop: getTableMarginTop(
              filterStore.savedFiltersHeight,
              Boolean(Object.keys(safetyWorkListStore.filterParams).length),
              filterStore.isOpenAdvancedSearch ||
                filterStore.isOpenedSavedFilterOptions ||
                filterStore.isOpenedColumnsOptions,
              browserName === "Firefox"
            ),
            transition: "0.2s"
          }}
        >
          <thead
            style={{
              top: getTHeadTop(
                filterStore.savedFiltersHeight,
                Boolean(Object.keys(safetyWorkListStore.filterParams).length),
                filterStore.isOpenedSavedFilters && browserName === "Firefox"
              )
            }}
          >
            <tr>
              {safetyWorkListStore.currentTitles.map((title) => {
                return (
                  <th key={title} className={styles.title}>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => title !== "building" && filterList(title)}
                      id={`SafetyWorkListTable_sortBy_${title}`}
                    >
                      <p className="m-0">
                        {safetyWorkListStore.safetyWorkCols[title]["title"]}
                      </p>
                      {title !== "building" ? (
                        <img
                          src={iconArrowDouble}
                          alt="sort"
                          className={
                            title === safetyWorkListStore.safetyWorkListOrder
                              ? styles.activeSortIcon
                              : ""
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {safetyWorkListStore.safetyWorkList.length ? (
              safetyWorkListStore.safetyWorkList.map((cert, index) => {
                // пока с бэка не приходит отметка о сроке действия сертификата, это будет проверяться на фронте подсчетом количества дней до окончания сертификата
                const dayLeft = Math.ceil(
                  (new Date(cert["date_end"]).getTime() -
                    new Date().getTime()) /
                    (1000 * 3600 * 24)
                );
                return (
                  <tr
                    key={cert.id}
                    className={`${index % 2 ? styles.greyRow : ""} ${
                      cert.id === safetyWorkListStore.selectedCert
                        ? styles.selectedRow
                        : ""
                    }`}
                    id={`SafetyWorkListTable_getCert_${cert.id}`}
                    onClick={() => {
                      safetyWorkListStore.setSelectedCert(cert.id);
                      safetyWorkListStore.getOneCert(cert.id);
                      menuStore.setScrollPosition(menuStore.scroll);
                    }}
                  >
                    {safetyWorkListStore.currentTitles.map((title) => {
                      switch (title) {
                        case "tn":
                          return (
                            <td key={title}>
                              <Link
                                onClick={(event) => event.stopPropagation()}
                                to={`/staff/id=${cert["uid"]}`}
                                id={`SafetyWorkListTable_goToStaff_fromTN_${cert["uid"]}`}
                                target="_blank"
                                className={styles.link}
                              >
                                {cert[title]}
                              </Link>
                            </td>
                          );
                        case "type":
                        case "kind":
                          return (
                            <td key={title} className={styles.breakCell}>
                              {
                                safetyWorkListStore.safetyWorkSelects[title]?.[
                                  cert[title]
                                ]["title"]
                              }
                            </td>
                          );
                        case "uid":
                          return (
                            <td key={title} className={styles.breakCell}>
                              <Link
                                onClick={(event) => event.stopPropagation()}
                                to={`/staff/id=${cert[title]}`}
                                id={`SafetyWorkListTable_goToStaff_${cert[title]}`}
                                target="_blank"
                                className={styles.link}
                              >
                                {`${cert["surname"] || ""} ${
                                  cert["name"] || ""
                                } ${cert["patronymic"] || ""}`}
                              </Link>
                            </td>
                          );
                        case "company":
                          return <td key={title}>{cert["title_company"]}</td>;
                        case "date_start":
                          return (
                            <td key={title}>{getFormattedDate(cert[title])}</td>
                          );

                        case "date_end":
                          return (
                            <td key={title}>
                              <div className={styles.statusCert}>
                                {dayLeft < 30 && dayLeft > 0 ? (
                                  <img
                                    src={Hourglass}
                                    className={styles.hourglassIcon}
                                  />
                                ) : dayLeft <= 0 ? (
                                  <img
                                    src={IconAttention}
                                    className={styles.attentionIcon}
                                  />
                                ) : (
                                  ""
                                )}
                                {getFormattedDate(cert[title])}
                              </div>

                              {dayLeft < 30 && dayLeft > 0 ? (
                                <p
                                  className={`${styles.attention} ${styles.orange}`}
                                >
                                  Скоро закончится
                                </p>
                              ) : dayLeft <= 0 ? (
                                <p
                                  className={`${styles.attention} ${styles.red}`}
                                >
                                  Просрочено
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                          );
                        case "status_certificate":
                          return (
                            <td key={title}>
                              {cert[title] === "Ok" ? (
                                <div className={styles.statusCert}>
                                  <img
                                    src={BigCheck}
                                    className={styles.checkIcon}
                                  />
                                  Действует
                                </div>
                              ) : (
                                <div className={styles.statusCert__attention}>
                                  <img
                                    src={IconAttention}
                                    className={styles.attentionIcon}
                                  />
                                  Не действителен
                                </div>
                              )}
                            </td>
                          );
                        case "building":
                          return (
                            <td key={title}>
                              <div className={styles.buildingCell}>
                                {cert["events_type"] &&
                                  Object.entries(cert["events_type"]).map(
                                    ([key, value]) => {
                                      return (
                                        <div key={key}>
                                          <div className={styles.statusRow}>
                                            <div>
                                              ({value["title_company"]})
                                            </div>
                                            <div className={styles.status}>
                                              <StatusIcon
                                                icon={
                                                  safetyWorkListStore
                                                    .buildingEventsType[
                                                    value["type_event"]
                                                  ]?.custom.icon
                                                }
                                                color={
                                                  safetyWorkListStore
                                                    .buildingEventsType[
                                                    value["type_event"]
                                                  ]?.custom.color_api
                                                }
                                              />
                                              <p>
                                                {
                                                  safetyWorkListStore
                                                    .buildingEventsType[
                                                    value["type_event"]
                                                  ]?.title
                                                }
                                              </p>
                                            </div>
                                          </div>
                                          <Link
                                            onClick={(event) =>
                                              event.stopPropagation()
                                            }
                                            id={`SafetyWorkListTable_goToBuilding_${value["id_building"]}`}
                                            to={`../../building/id=${value["id_building"]}/timesheet`}
                                            target="_blank"
                                            className={styles.link}
                                          >
                                            {value["title_building"]}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </td>
                          );
                        default:
                          return <td key={title}>{cert[title]}</td>;
                      }
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className={styles.error}>
                <td colSpan={safetyWorkListStore.currentTitles.length}>
                  {safetyWorkListStore.errorMessage
                    ? safetyWorkListStore.errorMessage
                    : ""}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        ""
      )}
      {safetyWorkListStore.page !== 1 ? (
        <div className={styles.firstCol}>
          {safetyWorkListStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        <LoadedComponent
          isLoading={safetyWorkListStore.isLoading}
          errorMessage={safetyWorkListStore.errorMessage}
        />
      )}
      {safetyWorkListStore.selectedCert && !safetyWorkListStore.isLoading ? (
        <SafetyWorkCertWindow />
      ) : (
        ""
      )}
    </>
  );
};

export default observer(SafetyWorkListTable);
