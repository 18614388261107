import styles from "./staffOnePremiumLevelHistoryTotal.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import { BarChart, Bar, LabelList, XAxis, YAxis, Cell } from "recharts";

import { ColorDict } from "shared/utils/dictionaries/colorDict";
import { ReactComponent as IconBase } from "shared/assets/images/premiumLevelIcons/iconBase.svg";
import { ReactComponent as IconSilver } from "shared/assets/images/premiumLevelIcons/iconSilver.svg";
import { ReactComponent as IconGold } from "shared/assets/images/premiumLevelIcons/iconGold.svg";
import { ReactComponent as IconPlatinum } from "shared/assets/images/premiumLevelIcons/iconPlatinum.svg";
import { ReactComponent as IconDiamond } from "shared/assets/images/premiumLevelIcons/iconDiamond.svg";
import { Props } from "recharts/types/component/Label";
import Scrollbars from "react-custom-scrollbars-2";
import { months } from "shared/utils/dictionaries/months";

type ElementOfBar = {
  month: number;
  year: number;
  ws: number;
  level: string;
  levelTitle: string;
  color: string;
};

type PropsForTicks = {
  x: number;
  y: number;
  index: number;
  payload: { value: string | number; coordinate: number };
};

const iconLevel = {
  "5cdc983300eb9fdfd48258a590211ac4209e7b50": <IconBase />,
  "76830631ae8cbbc3020826f49b55b98517551f15": <IconSilver />,
  "8949920a557e1b28c6022f73faa48b996c9c4b17": <IconGold />,
  acfda54fb1685a51e0646a088b4177790dc127bd: <IconPlatinum />,
  b8495ca53df72bb3268af0fce3e4a6603a324e1c: <IconDiamond />
};

const StaffOnePremiumLevelHistoryTotal = () => {
  const { staffOnePremiumLevelStore } = useStores();
  const { id } = useParams();

  // список координат для отображения годов
  const coordinateForYear: Record<string, number> = {};

  // список координат для отображения уровней на оси Х
  const coordinateForLevel: Record<string, number> = {};
  // переменная для создания уникальных ключей в объекте coordinateForLevel
  let tempForLevelKey = "";

  // приводим данные с бэка к необходимому виду для отображения на диаграмме
  const getDataForBar = () => {
    if (
      staffOnePremiumLevelStore.premium_level_current[id]?.["history"] &&
      Object.values(
        staffOnePremiumLevelStore.premium_level_current[id]["history"]
      ).length
    ) {
      const dataForBar: ElementOfBar[] = [];
      let elementOfBar = {} as ElementOfBar;
      Object.values(
        staffOnePremiumLevelStore.premium_level_current[id]["history"]
      ).forEach((value) => {
        if (value["month"] && Object.values(value["month"]).length) {
          Object.values(value["month"]).forEach((month) => {
            elementOfBar = {} as ElementOfBar;
            elementOfBar["year"] = value["year"];
            elementOfBar["month"] = month["month"];
            elementOfBar["ws"] = month["ws"];
            elementOfBar["level"] = month["level"];
            elementOfBar["levelTitle"] =
              staffOnePremiumLevelStore.premium_level_selects[
                month["level"]
              ]?.title;
            elementOfBar["color"] =
              ColorDict[
                staffOnePremiumLevelStore.premium_level_selects[
                  month["level"]
                ]?.["custom"]?.["color"]
              ] || ColorDict["default"];

            dataForBar.push(elementOfBar);
          });
        }
      });
      return dataForBar;
    }
  };

  // функция для кастомизации значений уровней на оси Х
  const CustomizedXaxiStickLevel = (props: PropsForTicks) => {
    const { x, y, payload, index } = props;

    // получаем координаты для каждого первого из ряда одинаковых уровней
    getDataForBar().forEach((element, ind) => {
      if (tempForLevelKey !== element["levelTitle"] && index === ind) {
        coordinateForLevel[`${element["levelTitle"]}_${ind}`] = Math.trunc(
          payload["coordinate"]
        );
        tempForLevelKey = element["levelTitle"];
      }
    });

    // получаем цвет для каждого уровня
    const currentColor = getDataForBar().find(
      (element) => element["levelTitle"] === payload.value
    )["color"];
    return (
      <g transform={`translate(${x},${y})`}>
        <linearGradient
          id={`color_${payload.value}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
          key={`cell-${index}`}
        >
          <stop offset="0" stopColor={currentColor} stopOpacity={0.1} />
          <stop offset="1" stopColor={currentColor} stopOpacity={0.5} />
        </linearGradient>
        <rect
          x="-46"
          y="-7.5"
          width={"91px"}
          height="27px"
          stroke="none"
          fill={`url(#color_${payload.value})`}
        />
        {Object.values(coordinateForLevel).includes(
          Math.trunc(payload["coordinate"])
        ) ? (
          <text
            x={-40}
            y={10}
            style={{ fontSize: "14px", fontWeight: "400", fill: "#000000" }}
          >
            {payload.value}
          </text>
        ) : (
          ""
        )}
      </g>
    );
  };

  // функция для кастомизации значений месяцев на оси Х
  const CustomizedXaxiStickMonth = (props: PropsForTicks) => {
    const { x, y, payload, index } = props;

    // получаем координаты для каждого года
    getDataForBar().forEach((element, ind) => {
      if (index === ind && !(element["year"] in coordinateForYear)) {
        coordinateForYear[element["year"]] = payload["coordinate"];
      }
    });

    return (
      <g
        transform={`translate(${x},${y})`}
        onClick={() => handleClick(payload.value as number, index)}
      >
        <rect
          x="-46"
          y="-7.5"
          width="91px"
          height="50px"
          stroke={index === getDataForBar().length - 1 ? "#008CFF" : "#e7e7e7"}
          fill={index === getDataForBar().length - 1 ? "#F8FBE3" : "none"}
        />
        <text
          y={10}
          textAnchor="middle"
          style={{
            fontSize: "14px",
            fontWeight: "400",
            fill: "#008CFF",
            cursor: "pointer"
          }}
        >
          {months[payload.value]}
        </text>
        {index === getDataForBar().length - 1 ? (
          <text
            y={34}
            textAnchor="middle"
            style={{ fontSize: "14px", fontWeight: "500", fill: "#17A854" }}
          >
            Текущий
          </text>
        ) : (
          ""
        )}
      </g>
    );
  };

  // функция для кастомизации значений годов на оси Х
  const CustomizedXaxiStickYear = (props: PropsForTicks) => {
    const { y, payload } = props;

    return (
      <g transform={`translate(${coordinateForYear[payload.value] - 40},${y})`}>
        <rect
          x={-6}
          y="-7"
          width={`${
            Object.values(
              staffOnePremiumLevelStore.premium_level_current[id]["history"][
                payload.value
              ]["month"]
            ).length *
              91 +
            1
          }px`}
          height="35px"
          stroke="#e7e7e7"
          fill="none"
        />
        <text
          y={10}
          style={{ fontSize: "14px", fontWeight: "400", fill: "#000000" }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  // функция отображения иконок уровней на каждой диаграмме
  const RenderCustomizedLabel = (props: Props) => {
    const { value, x, y } = props;

    return (
      <g
        transform={`translate(${+x + 32},${+y - 30})`}
        mask={`url(#mask${value})`}
      >
        {iconLevel[value]}
      </g>
    );
  };

  // обновляем данные по нижней диаграммы, нажатием на месяцы первой диаграмма
  // выбранный месяц будет последним в новвом диапазоне второй диаграммы
  const handleClick = (month: number, ind: number) => {
    // ищем выбранный элемент из массива getDataForBar() для получения года
    const limitDate = getDataForBar().find(
      (element, index) => element["month"] === month && index === ind
    );
    limitDate &&
      Object.values(limitDate).length &&
      staffOnePremiumLevelStore.getDetailWorkshift(
        id,
        month,
        limitDate["year"]
      );
  };

  return (
    <Scrollbars
      style={{ width: "100%" }}
      autoHeight
      autoHeightMax="560px"
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
    >
      <div className={styles.barchart}>
        <BarChart
          width={getDataForBar().length * 91 + 72}
          height={412}
          data={getDataForBar()}
          barCategoryGap={1}
          barSize={90}
          maxBarSize={90}
        >
          <defs>
            {getDataForBar().map((entry, index) => (
              <linearGradient
                id={`color_${index}`}
                x1="0"
                y1="0"
                x2="1"
                y2="1"
                key={`cell-${index}`}
              >
                <stop offset="0" stopColor={entry.color} stopOpacity={0.2} />
                <stop offset="1" stopColor={entry.color} />
              </linearGradient>
            ))}
          </defs>
          <Bar dataKey="ws" barSize={90} maxBarSize={90} minPointSize={32}>
            {getDataForBar().map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`url(#color_${index})`}
                stroke={index === getDataForBar().length - 1 ? "#008CFF" : ""}
                strokeWidth={index === getDataForBar().length - 1 ? "2px" : ""}
              />
            ))}
            <LabelList
              dataKey="ws"
              position="insideBottom"
              fill="black"
              dy={-8}
              style={{ fontSize: "16px", fontWeight: "500" }}
              stroke="none"
            />
            <LabelList
              dataKey="level"
              content={(props) => <RenderCustomizedLabel {...props} />}
            />
          </Bar>
          <XAxis
            xAxisId="0"
            dataKey="levelTitle"
            tickLine={false}
            axisLine={{ stroke: "#9C9C9D" }}
            tick={(props: PropsForTicks) => (
              <CustomizedXaxiStickLevel {...props} />
            )}
            height={27}
          />
          <XAxis
            xAxisId="1"
            dataKey="month"
            tickLine={false}
            axisLine={false}
            tick={(props: PropsForTicks) => (
              <CustomizedXaxiStickMonth {...props} />
            )}
            height={50}
            // onClick={handleClick}
          />
          <XAxis
            xAxisId="2"
            dataKey="year"
            allowDuplicatedCategory={false}
            tickLine={false}
            axisLine={false}
            tick={(props: PropsForTicks) => (
              <CustomizedXaxiStickYear {...props} />
            )}
            height={40}
          />
          <YAxis
            type="number"
            domain={[0, 365]}
            tickLine={false}
            axisLine={{ stroke: "#9C9C9D" }}
            tick={{ fontSize: "14px", fontWeight: "400", fill: "#000000" }}
            tickCount={3}
          />
        </BarChart>
      </div>
    </Scrollbars>
  );
};

export default observer(StaffOnePremiumLevelHistoryTotal);
