import { isEmpty } from "lodash";
import {
  stringWithPuncAndLimit,
  stringWithNumAndPuncAndLimit,
  dateSchema,
  stringSchema
} from "shared/utils/validation/validation";

import {
  phoneSchema,
  pastDate,
  validationStaffForm,
  calculateFullAge
} from "shared/utils/validation/validationStaffForm";
import { array, object, string } from "yup";

const validationAddNewStaffSchema = {
  ...validationStaffForm,
  company: array().test("isEmpty", "Обязательное поле", (val) => {
    return !isEmpty(val);
  }),
  surname: stringWithPuncAndLimit,
  name: stringWithPuncAndLimit,
  patronymic: stringWithPuncAndLimit,
  phone: phoneSchema,
  pasp_place: stringWithNumAndPuncAndLimit,
  birth_place: stringWithPuncAndLimit,
  propisk: stringWithNumAndPuncAndLimit,
  living_adress: stringWithNumAndPuncAndLimit,
  pasp_date: pastDate("pasp_date"),
  birthday: dateSchema.test(
    "dob",
    "Кандидатом не может быть человек, недостигший 20 лет и не старше 70 лет",
    (val) => {
      if (val) {
        const years = calculateFullAge(Date.parse(String(val)))["years"];
        return !(years < 20 || years > 70);
      } else return true;
    }
  ),
  edu_date_start: dateSchema,
  training_date_start: dateSchema.test(
    "training_date_start",
    "Дата начала не может быть позже даты окончания",
    function (val) {
      if (this.parent.training_date_end && val) {
        return Boolean(this.parent.training_date_end >= val);
      } else return true;
    }
  ),
  training_date_finish: dateSchema.test(
    "training_date_finish",
    "Дата окончания не может быть ранее даты начала",
    function (val) {
      if (this.parent.training_date_start && val) {
        return Boolean(this.parent.training_date_start <= val);
      } else return true;
    }
  )
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {
    edu_date_start_all: dateSchema,
    companyAll: array().of(stringSchema)
  };
  const tempValidationSchema = {};
  cols.map((col) => {
    tempValidationSchema[col] =
      col in validationAddNewStaffSchema
        ? validationAddNewStaffSchema[col]
        : string().nullable();

    if (requiredCols.includes(col)) {
      tempValidationSchema[col] =
        col in validationAddNewStaffSchema
          ? validationAddNewStaffSchema[col]?.required("Обязательное поле")
          : string()?.nullable()?.required("Обязательное поле");
    }

    if (col.includes("phone")) {
      tempValidationSchema[col] = validationAddNewStaffSchema["phone"];
    }
  });
  validationSchema["staff"] = array().of(object(tempValidationSchema));

  return object(validationSchema);
};
