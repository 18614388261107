// функция для скачивания файла. Первым параметров принимает ссылку на файл, вторым - название файла с указанием расширения

export const downloadImage = async (imageSrc: string, fileName: string) => {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
