import { object } from "yup";
import { stringSchema } from "shared/utils/validation/validation";

const validation = object({
  search_value: stringSchema
    .test(
      "search_value",
      "Поиск работает либо по ТН, либо по ФИО. Ввод чисел и букв не допускается",
      (value) => {
        if (isNaN(+value) && /[0-9]/gi.test(value)) return false;
        else return true;
      }
    )
    .test("search_value", "Минимум 3 буквы для поиска по ФИО", (value) => {
      if (
        (value !== undefined && value) ??
        (isNaN(+value) &&
          !/[0-9]/gi.test(value) &&
          value.replace(/ /g, "").length < 3)
      ) {
        return false;
      } else return true;
    })
});

export default validation;
