import { classNames } from "shared/utils/helpers/classNames";
import styles from "./option.module.scss";
import { ButtonHTMLAttributes } from "react";
import { ReactComponent as IconCloseForBtn } from "shared/assets/images/mainIcons/iconsClose/iconCloseForBtn.svg";

interface OptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Стили, заданные в родителе для кнопки
   */
  className?: string;
  /**
   * Функция, отрабатывающая при клике если есть иконка крестик для удаления
   */
  onClick?: () => void;
  /**
   * Отображаемый текст
   */
  title?: string;
  /**
   * Если передать - не будет отображаться иконка-крестик
   */

  required?: boolean;
}

export const Option = ({
  title,
  className,
  onClick: onDelete,
  required,
  ...props
}: OptionProps) => {
  return (
    <button
      type="button"
      className={classNames(
        "",
        { [styles.option]: !required, [styles.required]: required },
        [className || ""]
      )}
      onClick={onDelete && onDelete}
      disabled={required}
      {...props}
    >
      {title || ""}
      {!required && <IconCloseForBtn className={styles.icon} />}
    </button>
  );
};
