import styles from "./staffOneSafetyWorkTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { Table } from "react-bootstrap";

import { getTableArray } from "features/StaffOneSafetyWork";
import React, { useEffect, useState } from "react";
import StaffOneSafetyWorkTableWindow from "./StaffOneSafetyWorkTableWindow";

type StaffOneSafetyWorkTableProps = {
  positions: Partial<{
    [key: string]: { [key: string]: string };
  }>;
  activePosition: string;
  activeRow: string;
  setActiveRow: (arg: string) => void;
};

const StaffOneSafetyWorkTable = ({
  positions,
  activePosition,
  activeRow,
  setActiveRow
}: StaffOneSafetyWorkTableProps) => {
  const { staffOneSafetyWorkStore } = useStores();

  const [openWindow, setOpenWindow] = useState(false);

  useEffect(() => {
    if (!openWindow) {
      setActiveRow("");
    }
  }, [openWindow]);

  const titles = [
    staffOneSafetyWorkStore.certsColsTable.kind.title,
    staffOneSafetyWorkStore.certsColsTable.type.title,
    staffOneSafetyWorkStore.certsColsTable.date_start.title.split(" ")
      .length === 3
      ? `${
          staffOneSafetyWorkStore.certsColsTable.date_start.title.split(" ")[0]
        } ${
          staffOneSafetyWorkStore.certsColsTable.date_start.title.split(" ")[1]
        }`
      : staffOneSafetyWorkStore.certsColsTable.date_start.title,
    staffOneSafetyWorkStore.certsColsTable.date_end.title
  ];

  return !staffOneSafetyWorkStore.certificates[
    staffOneSafetyWorkStore.selectedCompany
  ][activePosition] ? (
    <div className={styles.noData}>
      {`Не найдены сертификаты для должности ${
        positions[staffOneSafetyWorkStore.selectedCompany][activePosition]
      }`}
    </div>
  ) : staffOneSafetyWorkStore.selectedCompany !== "history" &&
    staffOneSafetyWorkStore.certificates[
      staffOneSafetyWorkStore.selectedCompany
    ][activePosition] ? (
    <>
      {Object.keys(
        staffOneSafetyWorkStore.certificates[
          staffOneSafetyWorkStore.selectedCompany
        ]
      ).map((position) => {
        const table = getTableArray(
          position,
          activePosition,
          staffOneSafetyWorkStore.certificates[
            staffOneSafetyWorkStore.selectedCompany
          ][activePosition]
        );

        return position === activePosition && table.length ? (
          <React.Fragment key={activePosition}>
            <Table borderless className={styles.table}>
              <thead>
                <tr
                  className={`${styles.thead} ${
                    activeRow ===
                    `${table[0]?.id}-${activePosition}-${
                      staffOneSafetyWorkStore.selectedCompany
                    }-${0}`
                      ? styles.activeRow_sibling
                      : ""
                  }`}
                >
                  {titles.map((title) => {
                    return (
                      <th key={title} className={styles.title}>
                        {title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {table.map((row, idx) => {
                  return (
                    <tr
                      key={row.id}
                      className={`${styles.tr} ${
                        row.color ? styles[row.color] : ""
                      } ${
                        activeRow ===
                        `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}`
                          ? styles.activeRow
                          : ""
                      } ${
                        activeRow ===
                        `${table[idx + 1]?.id}-${activePosition}-${
                          staffOneSafetyWorkStore.selectedCompany
                        }-${idx + 1}`
                          ? styles.activeRow_sibling
                          : ""
                      }`}
                    >
                      {Object.keys(row).map((cell) => {
                        if (cell === "color" || cell === "id") return;
                        return (
                          <td
                            className={styles.td}
                            key={`${cell}-${row.id}`}
                            id={`${cell}_${row.id}`}
                            onClick={() => {
                              activeRow !==
                                `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}` &&
                                setActiveRow(
                                  `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}`
                                );

                              setOpenWindow(true);
                            }}
                          >
                            {row[cell] ? row[cell] : "—"}
                          </td>
                        );
                      })}
                      {openWindow &&
                      activeRow ===
                        `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}` ? (
                        <StaffOneSafetyWorkTableWindow
                          setOpenWindow={setOpenWindow}
                          certificate={{
                            certificate: Object.values(
                              staffOneSafetyWorkStore.certificates[
                                staffOneSafetyWorkStore.selectedCompany
                              ][activePosition]
                            ).find((id) => id.id === activeRow.slice(0, 40)),
                            company: Object.values(
                              staffOneSafetyWorkStore.companiesTabs
                            ).find(
                              (id) =>
                                id.id ===
                                staffOneSafetyWorkStore.selectedCompany
                            )
                          }}
                          show={
                            openWindow &&
                            activeRow ===
                              `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}`
                          }
                        />
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : position === activePosition && !table.length ? (
          <div
            key={activePosition}
            className={styles.noData}
          >{`Не найдены сертификаты для должности ${
            positions[staffOneSafetyWorkStore.selectedCompany]?.[activePosition]
          }`}</div>
        ) : (
          ""
        );
      })}
    </>
  ) : null;
};

export default observer(StaffOneSafetyWorkTable);
