import styles from "./itemScrollBoardHeader.module.scss";
import { useRef } from "react";
import { observer } from "mobx-react-lite";
import SearchInput from "shared/ui/Inputs/SearchInput/index";

import { ReactComponent as IconSort } from "shared/assets/images/mainIcons/iconAZ.svg";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { classNames } from "shared/utils/helpers/classNames";

type ItemsScrollBoardHeaderProps = {
  searchPlaceholder?: string;
  notSearchable?: boolean;
  isSearchWithPagination?: boolean;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  notSortable?: boolean;
  otherItems: OptionWithTitle[];
  setOtherItems: (arg: OptionWithTitle[]) => void;
  searchInputValue: string;
  setSearchInputValue: (arg: string) => void;
};

const ItemsScrollBoardHeader = ({
  notSearchable,
  searchPlaceholder,
  isSearchWithPagination,
  getList,
  setSearchValue,
  searchValue,
  notSortable,
  otherItems,
  setOtherItems,
  searchInputValue,
  setSearchInputValue
}: ItemsScrollBoardHeaderProps) => {
  const inputRef = useRef<HTMLInputElement>();

  const sortItems = () => {
    const newArray = [...otherItems];
    newArray.reverse();
    setOtherItems(newArray);
  };

  const handleFindData = () => {
    setSearchValue(searchInputValue);
    getList();
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value.toLowerCase());
  };
  const handleClearSearchInput = () => {
    setSearchInputValue("");
    if (searchValue && isSearchWithPagination) {
      setSearchValue("");
      getList();
    }
  };

  return (
    <>
      {!notSearchable ? (
        <div
          className={classNames(styles.search, {
            [styles.withSort]: !isSearchWithPagination
          })}
          data-list="true"
        >
          <SearchInput
            inputRef={inputRef}
            onChange={handleChangeSearchInput}
            value={searchInputValue}
            handleFindData={isSearchWithPagination && handleFindData}
            clearSearch={handleClearSearchInput}
            placeholder={searchPlaceholder}
            fromList
          />
          {!isSearchWithPagination && !notSortable ? (
            <div className={styles.iconSort} data-list="true">
              <IconSort
                onClick={sortItems}
                id="ItemsScrollBoardHeader_sort"
                data-list="true"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default observer(ItemsScrollBoardHeader);
