import styles from "./staffOneSafetyWorkTableWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { Form, Formik } from "formik";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import StaffOneSafetyWorkTableWindowHistory from "./StaffOneSafetyWorkTableWindowHistory";
import Textarea from "shared/ui/Inputs/Textarea";
import FormWindow from "shared/ui/FormWindow";
import StatusIcon from "shared/ui/StatusIcon";

import { ReactComponent as Attachment } from "shared/assets/images/mainIcons/iconClip/iconClipWithoutStroke.svg";
import { ReactComponent as IconLeft } from "shared/assets/images/mainIcons/iconLeft/iconLeftWithoutStroke.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { fileUrl } from "stores/utils/consts";
import validationDate from "./validation";
import { format, addDays, sub } from "date-fns";
import { Certificate } from "stores/StaffModule/types/SafetyWork";
import { Company } from "stores/StaffModule/types/Company";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { downloadImage } from "shared/utils/helpers/downloadImage";
import { ViewField } from "shared/ui/ViewField";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneSafetyWorkTableWindowType = {
  certificate: { certificate: Certificate; company: Company };
  show: boolean;
  setOpenWindow: (openWindow: boolean) => void;
};

const StaffOneSafetyWorkTableWindow = ({
  certificate,
  show,
  setOpenWindow
}: StaffOneSafetyWorkTableWindowType) => {
  const { staffOneSafetyWorkStore } = useStores();
  const { id } = useParams();
  const [openForm, setOpenForm] = useState("detail");
  const [openedListName, setOpenedListName] = useState("");
  const [initialValues, setInitialValues] = useState<Partial<Certificate>>({});
  const [showFields, setShowFields] = useState(
    Object.values(staffOneSafetyWorkStore.certsShowFields["detail"])
  );
  useEffect(() => {
    setInitialValues(getInitialValues());
  }, []);

  useEffect(() => {
    switch (openForm) {
      case "detail":
        setShowFields(
          Object.values(staffOneSafetyWorkStore.certsShowFields["detail"])
        );
        break;
      case "add":
        setShowFields(
          Object.values(staffOneSafetyWorkStore.certsShowFields["add"])
        );
        break;
      case "edit":
        setShowFields(
          Object.values(staffOneSafetyWorkStore.certsShowFields["edit"])
        );
        break;
    }
  }, [openForm]);

  const getInitialValues = () => {
    const obj: Partial<Certificate> = {};

    showFields.map((field) => {
      switch (field) {
        case "time_create":
        case "kind":
        case "type":
        case "date_start":
        case "date_end":
        case "comment":
        case "company":
          obj[field] = certificate.certificate[field];
          break;
        default:
          obj[field] = certificate.certificate[field] || null;
          break;
      }
    });

    obj["file"] = certificate.certificate["file"];
    return obj;
  };

  const getInitialValuesForAdd = () => {
    const obj: Partial<Certificate> = {};

    showFields.map((field) => {
      switch (field) {
        case "time_create":
          obj[field] = format(new Date(), "yyyy-MM-dd");
          break;
        case "kind":
        case "type":
        case "company":
          obj[field] = certificate.certificate[field];
          break;
        case "date_start":
        case "date_end":
          obj[field] = null;
          break;
        case "comment":
          obj[field] = "";
          break;
        default:
          obj[field] = null;
          break;
      }
    });

    obj["file"] = null;
    return obj;
  };

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName(null) : setOpenedListName(name);
  };

  const history = certificate.certificate.history;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OverlayTrigger
        trigger="focus"
        defaultShow={show}
        show={show}
        placement="right"
        overlay={
          <Popover
            onClick={(event) => {
              event.stopPropagation();
              event.nativeEvent.stopImmediatePropagation();
            }}
          >
            <div className={styles.form}>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationDate}
                onSubmit={(values) => {
                  staffOneSafetyWorkStore.addCertificate(
                    id,
                    values.kind,
                    values.type,
                    values.date_start,
                    values.date_end,
                    values.comment,
                    certificate.company.id,
                    values.file as File
                  );
                }}
              >
                {({
                  values,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                  isValid,
                  initialValues,
                  dirty
                }) => {
                  return (
                    <div
                      className={
                        openForm === "detail" ? styles.btnForDetail : ""
                      }
                    >
                      <FormWindow
                        title={
                          openForm === "edit"
                            ? "Редактирование записи"
                            : openForm === "add"
                            ? "Добавление записи"
                            : "Просмотр записи"
                        }
                        setOpenWindow={setOpenWindow}
                        additionalChildren={
                          Object.values(history).length && (
                            <StaffOneSafetyWorkTableWindowHistory
                              history={history}
                              cols={staffOneSafetyWorkStore.certsColsTable}
                            />
                          )
                        }
                        saveBtnTitle={
                          openForm === "add" || openForm === "edit"
                            ? "Сохранить"
                            : values.date_start && values.date_end
                            ? "Редактировать"
                            : ""
                        }
                        saveBtnOnClickFunc={() => {
                          openForm === "detail"
                            ? setOpenForm("edit")
                            : setInitialValues(values);
                          if (openForm === "edit") {
                            setInitialValues(values);
                            setOpenForm("detail");
                            staffOneSafetyWorkStore.updateCertificate(
                              certificate.certificate.id,
                              values.uid,
                              values.kind,
                              values.type,
                              values.date_start,
                              values.date_end,
                              values.comment,
                              values.company,
                              values.file as File
                            );
                          }
                          if (openForm === "add") {
                            setInitialValues(values);
                            setOpenForm("detail");
                            handleSubmit();
                          }
                        }}
                        saveBtnDisabledValue={
                          openForm === "add"
                            ? !isValid ||
                              !dirty ||
                              !values.date_start ||
                              !values.date_end
                            : openForm === "edit"
                            ? !isValid || !dirty
                            : false
                        }
                        addBtnTitle={
                          openForm === "add" || openForm === "edit"
                            ? "Назад"
                            : "Добавить запись"
                        }
                        addBtnOnClickFunc={() => {
                          if (openForm === "detail") {
                            setOpenForm("add");
                            setInitialValues(getInitialValuesForAdd());
                          } else if (openForm === "add") {
                            setInitialValues(getInitialValues());
                            setOpenForm("detail");
                          } else {
                            setOpenForm("detail");
                          }
                        }}
                        addBtnImg={
                          openForm !== "detail" ? (
                            <IconLeft className={styles.backIcon} />
                          ) : null
                        }
                        cancelBtnTitle={openForm === "edit" && "Удалить запись"}
                        cancelBtnImg={<IconClose />}
                        hasBackBtn
                        cancelBtnOnClick={() => {
                          staffOneSafetyWorkStore.deleteСertificate(
                            certificate.certificate.id,
                            certificate.certificate.uid
                          );
                        }}
                        isScroll
                      >
                        <Form>
                          <div className={styles.inputs}>
                            {Object.keys(values).map((key) => {
                              switch (key) {
                                case "time_create":
                                  return (
                                    <div
                                      className={
                                        openForm !== "edit" &&
                                        openForm !== "add"
                                          ? styles.viewForm
                                          : ""
                                      }
                                      key={key}
                                    >
                                      <ViewField
                                        title={
                                          staffOneSafetyWorkStore
                                            .certsColsTable[key]["title"]
                                        }
                                        value={getFormattedDate(values[key])}
                                        disabled={openForm === "edit"}
                                      />
                                    </div>
                                  );
                                case "kind":
                                case "type":
                                  return (
                                    <div
                                      className={
                                        openForm !== "edit" &&
                                        openForm !== "add"
                                          ? styles.viewForm
                                          : ""
                                      }
                                      key={key}
                                    >
                                      <ViewField
                                        title={
                                          staffOneSafetyWorkStore
                                            .certsColsTable[key]["title"]
                                        }
                                        value={
                                          certificate.certificate[
                                            `${key}_title`
                                          ]
                                        }
                                        disabled={openForm === "edit"}
                                      />
                                    </div>
                                  );

                                case "date_start":
                                  return (
                                    <React.Fragment key={key}>
                                      {openForm === "edit" ||
                                      openForm === "add" ? (
                                        <div
                                          className={
                                            staffOneSafetyWorkStore
                                              .certsColsTable["date_end"]
                                              ? styles.dateContainer
                                              : ""
                                          }
                                        >
                                          <DatePickerField
                                            name={key}
                                            title={
                                              staffOneSafetyWorkStore
                                                .certsColsTable[key]["title"]
                                            }
                                            isCalendarOpened={
                                              openedListName === key
                                            }
                                            setIsCalendarOpened={() => {
                                              changeOpenedWindows(key);
                                            }}
                                            onChange={(startDate) => {
                                              const duration =
                                                staffOneSafetyWorkStore
                                                  .certsTypeList[values.type]
                                                  .custom.duration;
                                              if (duration < 10000) {
                                                const autoEndDate = addDays(
                                                  new Date(startDate),
                                                  duration
                                                );
                                                setFieldValue(
                                                  "date_end",
                                                  format(
                                                    autoEndDate,
                                                    "yyyy-MM-dd"
                                                  )
                                                );
                                              }
                                            }}
                                            placement="right"
                                            withClearBtn
                                          />
                                          {staffOneSafetyWorkStore
                                            .certsColsTable["date_end"] && (
                                            <DatePickerField
                                              name="date_end"
                                              title={
                                                staffOneSafetyWorkStore
                                                  .certsColsTable.date_end[
                                                  "title"
                                                ]
                                              }
                                              isCalendarOpened={
                                                openedListName === "date_end"
                                              }
                                              setIsCalendarOpened={() => {
                                                changeOpenedWindows("date_end");
                                              }}
                                              onChange={(endDate) => {
                                                const duration =
                                                  staffOneSafetyWorkStore
                                                    .certsTypeList[values.type]
                                                    .custom.duration;
                                                if (duration < 10000) {
                                                  const autoStartDate = sub(
                                                    new Date(endDate),
                                                    { days: duration }
                                                  );
                                                  setFieldValue(
                                                    "date_start",
                                                    format(
                                                      autoStartDate,
                                                      "yyyy-MM-dd"
                                                    )
                                                  );
                                                }
                                              }}
                                              withClearBtn
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          className={
                                            staffOneSafetyWorkStore
                                              .certsColsTable["date_end"]
                                              ? `${styles.dateContainer} ${styles.viewForm}`
                                              : styles.viewForm
                                          }
                                        >
                                          <ViewField
                                            title={
                                              staffOneSafetyWorkStore
                                                .certsColsTable[key]["title"]
                                            }
                                            value={getFormattedDate(
                                              values[key]
                                            )}
                                          />
                                          {staffOneSafetyWorkStore
                                            .certsColsTable["date_end"] && (
                                            <ViewField
                                              title={
                                                staffOneSafetyWorkStore
                                                  .certsColsTable.date_end
                                                  ?.title
                                              }
                                              value={getFormattedDate(
                                                values.date_end
                                              )}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );

                                case "date_end":
                                  if (
                                    !staffOneSafetyWorkStore.certsColsTable[
                                      "date_start"
                                    ]
                                  ) {
                                    return (
                                      <React.Fragment key={key}>
                                        {openForm === "edit" ||
                                        openForm === "add" ? (
                                          <DatePickerField
                                            name={key}
                                            title={
                                              staffOneSafetyWorkStore
                                                .certsColsTable[key]["title"]
                                            }
                                            isCalendarOpened={
                                              openedListName === key
                                            }
                                            setIsCalendarOpened={() => {
                                              changeOpenedWindows(key);
                                            }}
                                            placement="left"
                                            withClearBtn
                                          />
                                        ) : (
                                          <div className={styles.viewForm}>
                                            <ViewField
                                              title={
                                                staffOneSafetyWorkStore
                                                  .certsColsTable[key]["title"]
                                              }
                                              value={getFormattedDate(
                                                values[key]
                                              )}
                                            />
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  } else return;
                                case "comment":
                                  return (
                                    <div
                                      className={
                                        openForm !== "edit" &&
                                        openForm !== "add"
                                          ? styles.viewForm
                                          : ""
                                      }
                                      key={key}
                                    >
                                      {openForm !== "edit" &&
                                      openForm !== "add" ? (
                                        <ViewField
                                          value={values[key]}
                                          title={
                                            staffOneSafetyWorkStore
                                              .certsColsTable[key]["title"]
                                          }
                                        />
                                      ) : (
                                        <Textarea
                                          name={key}
                                          value={values[key] || ""}
                                          label={
                                            staffOneSafetyWorkStore
                                              .certsColsTable[key]["title"]
                                          }
                                          onChange={(event) => {
                                            handleChange(event);
                                          }}
                                          placeholder={
                                            staffOneSafetyWorkStore
                                              .certsColsTable[key]["title"]
                                          }
                                          commentCol={
                                            staffOneSafetyWorkStore
                                              .certsColsTable[key]
                                          }
                                        />
                                      )}
                                    </div>
                                  );

                                case "company":
                                  return (
                                    <div
                                      className={
                                        openForm !== "edit" &&
                                        openForm !== "add"
                                          ? styles.viewForm
                                          : ""
                                      }
                                      key={key}
                                    >
                                      <ViewField
                                        value={certificate.company.title}
                                        title={
                                          staffOneSafetyWorkStore
                                            .certsColsTable[key]["title"]
                                        }
                                        disabled={openForm === "edit"}
                                      />
                                    </div>
                                  );

                                case "file":
                                  return (
                                    <React.Fragment key={key}>
                                      {openForm !== "edit" &&
                                        openForm !== "add" &&
                                        values.file &&
                                        values.file !== -1 && (
                                          <div className={styles.fileContainer}>
                                            <div className={styles.displayFlex}>
                                              <Attachment
                                                className={styles.attachment}
                                              />
                                              <div
                                                className={
                                                  styles.displayFileName
                                                }
                                              >
                                                <div
                                                  className={styles.tooltip}
                                                  data-tooltip="Нажмите для просмотра изображения"
                                                >
                                                  <a
                                                    href={fileUrl + values.file}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                  >
                                                    {typeof values.file ===
                                                    "string"
                                                      ? values.file.slice(16)
                                                      : values.file
                                                      ? values.file?.name?.replace(
                                                          /^.*\\/,
                                                          ""
                                                        )
                                                      : typeof initialValues.file ===
                                                        "string"
                                                      ? initialValues.file?.slice(
                                                          16
                                                        )
                                                      : ""}
                                                  </a>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              onClick={() => {
                                                typeof values.file ===
                                                  "string" &&
                                                  downloadImage(
                                                    fileUrl + values.file,
                                                    values.file.slice(16)
                                                  );
                                              }}
                                              className={classNames(
                                                styles.icons,
                                                {},
                                                [styles.tooltipLeft]
                                              )}
                                              data-tooltip="Скачать изображение"
                                            >
                                              <StatusIcon
                                                icon="icondownloadwb"
                                                color="bw-gray5"
                                              />
                                            </div>
                                          </div>
                                        )}

                                      {(openForm === "edit" ||
                                        openForm === "add") && (
                                        <div className={styles.editFileInput}>
                                          <div className={styles.displayFlex}>
                                            <input
                                              type="file"
                                              id="file"
                                              accept=".jpg, .jpeg, .png, .gif, .ico, .csv, .xls, .xlsx, .txt, .doc, .docx, .pdf, .cdr, .zip"
                                              name={key}
                                              multiple={false}
                                              onChange={(event) => {
                                                setFieldTouched(key);
                                                setFieldValue(
                                                  key,
                                                  event.currentTarget.files[0]
                                                );
                                                (
                                                  document.getElementById(
                                                    "file"
                                                  ) as HTMLInputElement
                                                ).value = "";
                                              }}
                                              onBlur={handleBlur}
                                              hidden
                                            />
                                            <label htmlFor="file">
                                              <Attachment
                                                className={
                                                  styles.attachmentEdit
                                                }
                                              />
                                            </label>
                                            <div
                                              className={styles.displayFileName}
                                            >
                                              {openForm === "edit" ? (
                                                typeof values.file ===
                                                "object" ? (
                                                  values.file.name.replace(
                                                    /^.*\\/,
                                                    ""
                                                  )
                                                ) : values.file &&
                                                  values.file !== -1 ? (
                                                  values.file.slice(16)
                                                ) : (
                                                  <div>Прикрепить файл</div>
                                                )
                                              ) : typeof values.file ===
                                                  "object" &&
                                                values.file?.name ? (
                                                values.file.name.replace(
                                                  /^.*\\/,
                                                  ""
                                                )
                                              ) : (
                                                <div>Прикрепить файл</div>
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            className={styles.buttonsContainer}
                                          >
                                            {values.file &&
                                              values.file !== -1 &&
                                              !dirty && (
                                                <>
                                                  <div
                                                    className={classNames(
                                                      styles.icons,
                                                      {},
                                                      [styles.tooltipLeft]
                                                    )}
                                                    data-tooltip="Скачать изображение"
                                                  >
                                                    <StatusIcon
                                                      icon="icondownloadwb"
                                                      color="bw-gray5"
                                                    />
                                                  </div>
                                                  <div
                                                    onClick={() =>
                                                      staffOneSafetyWorkStore.deleteFile(
                                                        certificate.certificate
                                                          .id,
                                                        certificate.certificate
                                                          .uid
                                                      )
                                                    }
                                                    id="StaffOneSafetyWorkWindow_deleteFile"
                                                    className={classNames(
                                                      styles.iconBasket,
                                                      {},
                                                      [styles.tooltipLeftRed]
                                                    )}
                                                    data-tooltip="Удалить изображение"
                                                  >
                                                    <StatusIcon
                                                      icon="iconbasket"
                                                      color="bw-gray5"
                                                    />
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );

                                case "user":
                                  return (
                                    <div
                                      className={
                                        openForm !== "edit" &&
                                        openForm !== "add"
                                          ? styles.viewForm
                                          : ""
                                      }
                                      key={key}
                                    >
                                      <ViewField
                                        title={
                                          staffOneSafetyWorkStore
                                            .certsColsTable[key]["title"]
                                        }
                                        value={values[key]}
                                        disabled={openForm === "edit"}
                                      />{" "}
                                    </div>
                                  );
                                case "id":
                                case "uid":
                                  return;

                                default:
                                  if (
                                    staffOneSafetyWorkStore.certsColsTable[key]
                                  ) {
                                    return (
                                      <div
                                        className={
                                          openForm !== "edit" &&
                                          openForm !== "add"
                                            ? styles.viewForm
                                            : ""
                                        }
                                        key={key}
                                      >
                                        <Input
                                          disabled={
                                            openForm !== "edit" &&
                                            openForm !== "add"
                                          }
                                          name={key}
                                          label={
                                            staffOneSafetyWorkStore
                                              .certsColsTable[key]["title"]
                                          }
                                          onChange={(event) => {
                                            handleChange(event);
                                          }}
                                        />
                                      </div>
                                    );
                                  } else return;
                              }
                            })}
                          </div>
                        </Form>
                      </FormWindow>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Popover>
        }
      >
        {({ ref, ...triggerHandler }) => {
          return (
            <td
              className={styles.form__td}
              onClick={(event) => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
              }}
              id="StaffOneSafetyWorkTableWindow_editForm"
              data-window={true}
              {...triggerHandler}
            >
              <div ref={ref} />
            </td>
          );
        }}
      </OverlayTrigger>
    </ErrorBoundary>
  );
};

export default observer(StaffOneSafetyWorkTableWindow);
