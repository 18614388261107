import styles from "./buildingOneWorkshiftStaffHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import SearchInput from "shared/ui/Inputs/SearchInput";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import BuildingOneWorkshiftHeaderWorkshift from "./BuildingOneWorkshiftHeaderWorkshift";

const BuildingOneWorkshiftStaffHeader = () => {
  const { id } = useParams();
  const { buildingOneWorkshiftStore, menuStore } = useStores();

  const [searchInputValue, setSearchInputValue] = useState(
    buildingOneWorkshiftStore.searchValue[id] || ""
  );

  const handleChangeInputValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInputValue(event.target.value.toLowerCase());
    buildingOneWorkshiftStore.setSearchValue(
      id,
      event.target.value.toLowerCase()
    );
  };

  const handleClearInputValue = () => {
    setSearchInputValue("");
    if (buildingOneWorkshiftStore.searchValue) {
      buildingOneWorkshiftStore.setSearchValue(id, "");
    }
  };

  const handleClickOnSaveBtn = () => {
    if (
      !buildingOneWorkshiftStore.selects.status?.[
        buildingOneWorkshiftStore.selectedOne.status
      ]?.custom.done
    ) {
      buildingOneWorkshiftStore.editStatus(
        id,
        Object.values(buildingOneWorkshiftStore.selects.status).find(
          (type) => type.custom.done === "on"
        ).id,
        buildingOneWorkshiftStore.selectedOne.comment,
        true
      );
    }
    buildingOneWorkshiftStore.setOpenedMode(id, "show");
  };

  const handleClickOnAddBtn = () => {
    if (
      !buildingOneWorkshiftStore.selects.status?.[
        buildingOneWorkshiftStore.selectedOne.status
      ]?.custom.done
    ) {
      buildingOneWorkshiftStore.editStatus(
        id,
        Object.values(buildingOneWorkshiftStore.selects.status).find(
          (type) => type.custom.done === "on"
        ).id,
        buildingOneWorkshiftStore.selectedOne.comment,
        true
      );
    }
    buildingOneWorkshiftStore.setOpenedMode(id, "show");
  };

  useEffect(() => {
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("workshiftOne");
    menuStore.setOpenedSubmodule("workshiftOneStaff");
    buildingOneWorkshiftStore.setSelectedWorkshiftForInfo(id, "/staff");
  }, [id]);

  return (
    <>
      {/* Данные смены с кнопками редактирования и экспорта */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BuildingOneWorkshiftHeaderWorkshift />
      </ErrorBoundary>

      {/* Строка поиска с кнопками сохранения/отмены */}
      <div className={styles.searchRow}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SearchInput
            value={searchInputValue}
            onChange={handleChangeInputValue}
            clearSearch={handleClearInputValue}
          />
        </ErrorBoundary>
        {buildingOneWorkshiftStore.openedMode[id] === "edit" ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ButtonsGroupForSettings
              saveBtnTitle="Сохранить"
              saveBtnOnClick={handleClickOnSaveBtn}
              addBtnTitle="Отменить"
              addBtnOnClick={handleClickOnAddBtn}
            />
          </ErrorBoundary>
        ) : null}
      </div>
    </>
  );
};

export default observer(BuildingOneWorkshiftStaffHeader);
