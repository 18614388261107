import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";
import { Widgets } from "../types/Widgets";
import { Col } from "../types/Col";
import { Conviction } from "../types/Conviction";
import { ApiResponse } from "stores/utils/types/ApiResponse";

export default class StaffOneConvictionStore {
  error = false;
  isLoading = false;

  convictions: { [key: string]: Conviction } = {};
  cols: { [key: string]: Col } = {};
  showFields: Widgets["show_fields"]["conviction"] = {};
  staffData: Partial<Widgets["staff"]> = {};

  selectedOneForConvictions: Partial<Widgets> = {};
  openedAllStaffForConvictions: { [key: string]: Partial<Widgets> } = {};

  setUpdateConvictions = (data?: Partial<Widgets>) => {
    if (data) {
      runInAction(() => {
        this.convictions = data["widgets"]["conviction"];
        this.staffData = data["staff"];
        this.showFields = data["show_fields"]["conviction"];
        this.cols = data["cols"]["conviction"];
      });
    } else {
      this.convictions = {};
      this.staffData = {};
      this.showFields = {};
      this.cols = {};
    }
  };

  setSelectedOneForConviction = (id: string) => {
    this.isLoading = false;
    this.error = false;

    this.setUpdateConvictions();
    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForConvictions[id];
    }

    if (
      Object.keys(this.openedAllStaffForConvictions).length &&
      this.openedAllStaffForConvictions[id]
    ) {
      if (Object.values(this.openedAllStaffForConvictions[id]).length) {
        this.selectedOneForConvictions = this.openedAllStaffForConvictions[id];

        this.setUpdateConvictions(this.selectedOneForConvictions);
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else this.getConvictionsForOneOfStaff(id);
  };

  getConvictionsForOneOfStaff = async (id: string) => {
    this.isLoading = true;
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields: "surname, name, patronymic, id, company, uid",
          widgets: ["conviction"],
          cols: "conviction",
          selects: "conviction"
        }
      });

      if (Object.values(data["staff"]).length) {
        this.openedAllStaffForConvictions[id] = data;
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: `${data["staff"]["surname"]} ${data["staff"]["name"]}`
        });
        if (this.rootStore.menuStore.tabId === id) {
          this.selectedOneForConvictions =
            this.openedAllStaffForConvictions[id];
          this.setUpdateConvictions(this.selectedOneForConvictions);
        }
        if (Object.keys(data["selects"]).length) {
          Object.entries(data["selects"]).forEach(([key, value]) => {
            const newDirectory = {};
            Object.values(value).forEach((item) => {
              newDirectory[item.id ? item.id : item.title] = {
                ...item,
                newname: item.id ? item.id : item.title,
                title:
                  key === "article" && item?.["number"]
                    ? `ст.${item["number"]} ${
                        item["part"] ? `ч.${item["part"]}` : ""
                      } ${item["title"]}`
                    : item["title"]
              };
            });

            this.cols[key] = {
              ...this.cols[key],
              directory: newDirectory
            };
          });
        }
      } else {
        this.openedAllStaffForConvictions[id] = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  addConviction = async (
    uid: string,
    article: string,
    date_start: string,
    punishment: string,
    term_type: string,
    comment: string
  ) => {
    const formData = {
      uid,
      article,
      date_start,
      punishment,
      term_type,
      comment
    };
    this.isLoading = true;

    try {
      const data: { errors: string } = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "conviction",
        method: "addRecord",
        body: formData
      });

      if (!data.errors) {
        await this.getConvictionsForOneOfStaff(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  editConviction = async (
    uid: string,
    id: string,
    article: string,
    date_start: string,
    punishment: string,
    term_type: string,
    comment: string
  ) => {
    const formData = {
      id,
      "update[article]": article,
      "update[uid]": uid,
      "update[date_start]": date_start,
      "update[punishment]": punishment,
      "update[term_type]": term_type,
      "update[comment]": comment
    };
    this.isLoading = true;

    try {
      const data: ApiResponse<boolean | 0> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "conviction",
          method: "updateRecord",
          body: formData
        });

      if (data.result) {
        this.getConvictionsForOneOfStaff(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
